import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { signInEvaluatorThunk, signInThunk } from './authSlice';
import toast from 'react-hot-toast';
import AdminSignin from './signInForms/AdminSignin';
import EvaluatorSignin from './signInForms/EvaluatorSignin';
import image from '../../utilities/Connectifie.png';
//import background from '../../images/cardbase.png';
//import AdminHeader from '../../components/AdminHeader/AdminHeader';

//const useAnimSnippet= <UseAnimations size={40} wrapperStyle={{ marginTop: '5px' }} animation={checkBox} />

export const greetTime = () => {
	const today = new Date();
	const hourOfDay = today.getHours(); //The current hour of today
	if (hourOfDay > 11 && hourOfDay <= 16) {
		return 'afternoon';
	} else if (hourOfDay > 16) {
		return 'evening';
	} else {
		return 'morning';
	}
}

export function SigninPage() {
	const { register, handleSubmit } = useForm();
	const dispatch = useDispatch();

	const auth = useSelector((state) => state.auth);

	const navigation = useNavigate();

	// navigate to dashboard on auth
	useEffect(() => {
		//const token = sessionStorage.getItem('token') ? sessionStorage.getItem('token') : false;
		const { token, evaluator } = auth;
		if (evaluator && evaluator.token) {
			navigation('/evaluator');
		}
		else if (token) {
			navigation('/admin');
		}
		/*if ( auth.token ) {
			navigation('/admin');
		}*/
	}, [auth, navigation]);

	const [ currentForm, setCurrentForm ] = useState('evaluator');
	const handleChangeCurrentForm = (e) => {
		setCurrentForm(e.target.value);
	}

	const onSubmitForm = (e) => {
		dispatch(signInThunk({...e}));
	};
	const onEvaluatorSignin = (e) => {
		dispatch(signInEvaluatorThunk({...e}));
	};

	return (
		<div
			className={`flex flex-col bg-white ${currentForm === 'admin'? 'md:bg-blue-800' : 'md:bg-accessOrange'} justify-center items-center w-full`}
			style={{  backgroundSize: 'cover', height: '100vh' }}
		>
			{/*<AdminHeader />*/}
			<div
				className={`flex flex-col bg-inherit justify-center items-center rounded-3xl ${currentForm === 'admin'? 'md:bg-blue-100' : 'md:bg-orange-100'} p-4 md:p-0 overflow-hidden w-full md:w-11/12 shadow-md`}
				style={{
					backgroundRepeat: 'no-repeat',
					backgroundSize: 'cover',
					height: '90vh',
				}}
			>
				<div className="bg-white flex flex-col self-start justify-center items-center w-full h-full md:w-1/2">
					<img className=" w-1/3" src={image} alt="logo" />
				{
					currentForm === 'admin' ? <AdminSignin onSubmitForm={onSubmitForm} /> : <EvaluatorSignin onSubmitForm={onEvaluatorSignin} />
				}
					<form onChange={handleChangeCurrentForm} className='p-4 rounded border shadow-inner m-3 mt-5'>
						<label className='m-3 font-bold text-blue-600'>
							<input className='mx-2' type='radio' name='signin' value='admin'
							/>
							Admin
						</label>
						
						<label className='m-3 font-bold text-blue-600'>
							<input className='mx-2' type='radio' defaultChecked name='signin' value='evaluator'
							/>
							Evaluator
						</label>
					</form>
				</div>
				

				

			</div>
		</div>
	);
}

export default SigninPage;
