import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
/*import {
	ChevronRightRounded,
	KeyboardArrowDownRounded
} from '@material-ui/icons';*/
// import AdminHeader from '../components/AdminHeader/AdminHeader';
import AdminSidebar from '../components/AdminSidebar/AdminSidebar';
import EvaluatorHeader from '../components/evaluatorHeader/EvaluatorHeader';
import EvaluatorSummary from '../evaluator-features/summary/EvaluatorSummary';

const pages = [
	{
		id: 0,
		title: 'My Summary',
		// icon: <HomeRounded />,
		page: <EvaluatorSummary />,
	},
];

const EvaluatorDashboard = () => {
	const dispatch = useDispatch();
	const navigation = useNavigate();
	const auth = useSelector((state) => state.auth);


    // get value from session storage, if null default to 0
	const [currentPageID, setCurrentPageID] = useState(
		sessionStorage.getItem('evalPage') ? Number(JSON.parse(sessionStorage.getItem('evalPage'))) : 0
	);

	useEffect(()=>{
		const token = sessionStorage.getItem('evaluator_token') ? sessionStorage.getItem('evaluator_token') : false;
		if (!token) {
			navigation('/');
			toast.remove();
			toast('You have been logged out');
		}
	}, [navigation, auth]);

	const setSelectedPageID = (id) => {
		setCurrentPageID(id);
		// persist selected page in session storage
		sessionStorage.setItem('evalPage', id);
	};

	return (
		<div>
			<EvaluatorHeader />
			<div className="flex h-full w-full" style={{ height: '100%'}}>
				{/* map through pages and only render page that matches id */}
				{pages.map((page) =>
					page.id === currentPageID ? (
						<div key={page.title} className="w-full">
							{page.page}
						</div>
					) : null
				)}
			</div>
		</div>
	);
};

export default EvaluatorDashboard;