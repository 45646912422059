import axios from "axios";
import toast from "react-hot-toast";
import { today } from "./Report";

const baseURL = 'http://18.134.133.59:4000/api/v1.0';

export const fetchReport = async (date= today, token) =>{
    //const response = await axios.get(`${baseURL}/home/${date}`);
    try{
        if (date !== today) { toast.remove(); toast.loading(`Loading Evaluator reports for ${date}`);}
        const { data } = await axios.get(`${baseURL}/home/${date}?campaign_id=2`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });

        const typeReports = data.allDaysOfAssessment.includes(date)? await axios.get(`${baseURL}/evaluator/assessment/report/${date}?campaign_id=2`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }) : null;

        const { data: flaggedEntries } = await axios.get(`${baseURL}/flag/flagged-branches/${date}?campaign_id=2`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        const flagged = flaggedEntries.allflag;

        toast.remove();
        if (date !== today) typeReports? toast.success("Data Fetched"): toast.error("No Data Found");
        
        const overview= data.allAssessment? data.allAssessment.map(entry => {
            return {
                id: entry.evaluator_assessment_id,
                evaluator_code: entry.evaluator_access_code,
                type: entry.type,
                branch_id: entry.campaign_assessment_branch_id,
                score: entry.totalScore
            }
        }) : [];

        const branch = typeReports? typeReports.data.BRANCH.map(entry => {
            return {
                ...entry.scores_in_category,
                ...entry
            }
        }) : [];

        const fle= typeReports? typeReports.data.FLE.map(entry => {
            return {
                ...entry.scores_in_category,
                ...entry
            }
        }) : [];

        const security= typeReports? typeReports.data.SECURITY.map(entry => {
            return {
                ...entry.scores_in_category,
                ...entry
            }
        }) : [];

        const evaluatorEntries = {
            overview: overview.reverse(),
            branch: branch.reverse(),
            fle: fle.reverse(),
            security: security.reverse(),        
        }

        const dateOfReports= data.allDaysOfAssessment? data.allDaysOfAssessment.reverse() : [];
        const evaluatorsOfDay= data.numberOfEvalutor? data.numberOfEvalutor : 0;
        const bankAssessed= data.bankAssessed? data.bankAssessed : 0;
        const regionCover= data.regionCover? data.regionCover : 0;
        const staffAssessed= data.staffAssessed? data.staffAssessed : 0;
        const group= data.group? data.group : 0;

        return {
            entries: evaluatorEntries,
            dates: dateOfReports,
            totalEvaluators: evaluatorsOfDay,
            bankAssessed,
            staffAssessed,
            regionCover,
            group,
            flaggedEntries: flagged,
        };
    }catch(error){
        toast.remove();
        toast.error("Error fetching data");
        console.log({error});
    };
    
};

export const unflagReport = async (token, flagID) =>{
    const {data: response} = await axios.post(`${baseURL}/flag/delete/${flagID}`);
    console.log({response})
    return response.success? toast.success(response.msg) : null;
}

export const acceptFlagReport = async (token, flagID) =>{
    const {data: response} = await axios.post(`${baseURL}/flag/approve/${flagID}`);
    console.log({response})
    return response.success? toast.success(response.msg) : null;
}



export const fetchReportByEvaluator = async (date= today, token) =>{};

export const fetchFlaggedEntries = async (date= today, token) =>{
    
}