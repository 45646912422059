import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { fetchFlaggedReports, unflagReport, acceptFlagReport } from "./flaggedIssuesAPI";

const initialState = {};
const reducers = {};

export const flaggedThunk = createAsyncThunk('flagged/load', async (date, { getState })=>{
    const { token } = getState().auth;
    const payload = await fetchFlaggedReports(date, token);
    return payload;
});

export const unflagReportThunk = createAsyncThunk('flagged/unflag', async (flagID, { getState })=>{
    const { token } = getState().auth;
    const payload = await unflagReport(token, flagID);
    return payload;
});

export const acceptFlagThunk = createAsyncThunk('flagged/acceptFlag', async (flagID, { getState })=>{
    const { token } = getState().auth;
    console.log({ token })
    const payload = await acceptFlagReport(token, flagID);
    return payload;
});

const flaggedSlice = createSlice({
    name: 'flagged',
    initialState,
    reducers,
    extraReducers: (builder)=>{
        builder
            .addCase(flaggedThunk.fulfilled, (state, action)=> ({ ...action.payload }))
    }
});

export default flaggedSlice.reducer;