/* eslint-disable react-hooks/exhaustive-deps */
import DonutChart from "../../components/charts/DonutChart";
import ColumnChart from "../../components/charts/ColumnChart";
import ProgressBar from "../../components/charts/ProgressBar";
import LineChart from "../../components/charts/LineChart";
import React, { useEffect, useState } from "react";
//import Table from "../../components/tables/Table";
import { useDispatch, useSelector } from "react-redux";
//import ReactHtmlTableToExcel from "react-html-table-to-excel";
//import { today } from "../report/Report";
import { pastWeekZoneScopeTrendThunk } from "./summarySlice";

const date= new Date();
const todayString= date.toLocaleString('en-us');

const AdminSummary = () =>{
    const dispatch = useDispatch();    
    const { overallScopeCoverage, dailyScopeCoverage, zonalTrend } = useSelector(state => state.dataSummary);
    const { dates } = useSelector(state => state.reports);

    
    // ALL THINGS AND DATA DAILY SCOPE
    const [dailyScopeFilter, setDailyScopeFilter] = useState("type");
    const handleChangeDailyScopeChart = (e) => {
        setDailyScopeFilter(e.target.value);
    };

    const makeDailyData = () => {
        if(dailyScopeCoverage && dailyScopeFilter === 'group'){
            return Object.values(dailyScopeCoverage.groupScope).map((group, index) => {
                return {
                    title: 'Group ' + (index + 1),
                    data: [group.done, (group.assigned - group.done)]
                };
            });
        } else if (dailyScopeCoverage && dailyScopeFilter === 'type'){
            return Object.values(dailyScopeCoverage.assessmentTypeScope).map((type, index) => {
                return {
                    title: 'Type ' + (index + 1),
                    data: [type.done, (type.assigned - type.done)]
                };
            });
        } else if (dailyScopeCoverage && dailyScopeFilter === 'zone'){
            return Object.values(dailyScopeCoverage.zoneScope).map(type => {
                return Math.round((type.done/type.assigned) * 100);
            });
        } else { return [] }
    };

    const dailyChartData= {
        groups: [
            {
                title: 'LPA group',
                data: dailyScopeCoverage? makeDailyData()[0].data : []// ([35, 40-35].data changed to type scope for more details) values gotten from summing the multiplication of branches scope of group and types scope
            },
            {
                title: 'Group 2',
                data: dailyScopeCoverage? makeDailyData()[1].data : []
            },
            {
                title: 'Group 3',
                data: dailyScopeCoverage? makeDailyData()[2].data : []
                //data: [13, 15-13] // lesser detail in forming graph: not best implementationm
            },
        ],

        types: [
            {
                title: 'Branch',
                data: makeDailyData()[0]? makeDailyData()[0].data: [0, 0]
            },
            {
                title: 'FLE',
                data: makeDailyData()[0]? makeDailyData()[1].data: [0, 0]
            },
            {
                title: 'Security',
                data: makeDailyData()[0]? makeDailyData()[2].data: [0, 0],
            }
        ],

    }

    const [dailyScopeChart, setDailyScopeChart] = useState([]);
    useEffect(() => {
        if(dailyScopeFilter === 'group'){
            setDailyScopeChart(dailyChartData.groups);
        }else if(dailyScopeFilter === 'type'){
            setDailyScopeChart(dailyChartData.types);
        }else { return null }
    }, [dailyScopeFilter]);

   
    // ALL THINGS PROJECT CAMPAIGN SCOPE COVERAGE
    const progressBarData = () => {
        if (overallScopeCoverage && overallScopeCoverage.groupScope) {
            const assignedArray = [];
            const doneArray = [];

            Object.values(overallScopeCoverage.groupScope).forEach(group => {
                assignedArray.push(group.assigned);
                doneArray.push(group.done);
            })
            return Math.round(doneArray.reduce((prev, curr)=> prev + curr) / assignedArray.reduce((prev, curr)=> prev + curr) * 100)
        }
    }

    // Zonal trend data
    const makeZonalTrendData= () =>{
        const data = zonalTrend? zonalTrend.map(day=> {
            return [...Object.values(day)];
        }) : [];
        return {
            lpa: data.map(day=> day[0]),
            nc: data.map(day=> day[1]),
            ne: data.map(day=> day[2]),
            nw: data.map(day=> day[3]),
            se: data.map(day=> day[4]),
            ss: data.map(day=> day[5]),
            sw: data.map(day=> day[6]),
        };
    }

    const pastWeekInDates = [];
    for (let i = 0; i < 7; i++) {
        if(dates) pastWeekInDates.push(dates[i]);
    }

    useEffect(()=>{
        dispatch(pastWeekZoneScopeTrendThunk(pastWeekInDates));
    }, [dates]);


    // To Filter Chart Display by Type, Group and Zone
    const [projectChartFilter, setProjectChartFilter] = useState("types");
    const handleChangeProjectChart = (e) => {
        setProjectChartFilter(e.target.value);
        // setProjectScopeFilter('groupTypes'); if there was groupStates this will be necessary
    };

    // To Filter Display of Each Group's Type (or state if it was available) scope coverage data
    const [projectScopeFilter, setProjectScopeFilter] = useState('groupTypes')
    const handleProjectScopeFilter= (e) => {
        setProjectScopeFilter(e.target.value);
    };

    const [showGroupNumber, setShowGroupNumber] = useState(0);
    const handleSetGroupDetailChart = (groupName) => {
        if (groupName === 'LPA') {
            setShowGroupNumber(0);
        } else if (groupName === 'Group 2') {
            setShowGroupNumber(1);
        } else if (groupName === 'Group 3') {
            setShowGroupNumber(2);
        } else { return null }
    }

    // Auto-generate data for overall project scope chart
    const makeProjectChartData = () => {
        if(overallScopeCoverage && projectChartFilter === 'groups'){
            return Object.values(overallScopeCoverage.groupScope).map(group => {
                return [group.done, (group.assigned- group.done)];
            });
        } else if (overallScopeCoverage && projectChartFilter === 'types'){
            return Object.values(overallScopeCoverage.assessmentTypeScope).map(type => {
                return [type.done, (type.assigned- type.done)];
            });
        } else if (overallScopeCoverage && projectChartFilter === 'zones'){
            return Object.values(overallScopeCoverage.zoneScope).map(zone => {
                return [zone.done, (zone.assigned- zone.done)];
            });
        } else { return [] }
    };

    // Auto-generate data for groupTypes scope coverage chart
    const makeGroupTypeData = () => {
        if(overallScopeCoverage && projectChartFilter === 'groups'){
            const groupTypesScope = Object.values(overallScopeCoverage.groupScope).map(group => {
                const data= Object.values(group.type).map(type => [type.done, (type.branchAssigned - type.done)]);
                return data;
            })
            console.log({ groupTypesScope });
            return showGroupNumber === 0? groupTypesScope[0] : showGroupNumber === 1? groupTypesScope[1] : groupTypesScope[2];
        } else{
            return [];
        }
        
    }

    makeGroupTypeData();

    // data skeleton for overall campaign charts
    const projectCampaignChartData= {
        groups: {
                titleText: 'Group Progress (%)',
                data: makeProjectChartData(),
                xaxis: ['LPA', 'Group 2', 'Group 3'],
        },
        types: {
            titleText: 'Assessment Type Progress (%)',
            data: makeProjectChartData(),
            xaxis: ['Branch', 'FLE', 'Security'],
        },
        zones: {
            titleText: 'Zone Progress (%)',
            data: makeProjectChartData(),
            xaxis: ['South West', 'South East', 'South South', 'North East', 'North West', 'North Central'],
        },
        groupStates: [
            {
                name: 'LPA Group Summary',
                cat: ['Lagos', 'Rivers', 'Abuja'],
                data: [50, 85, 55] //group project scope data by project scope filter: overall, branch, FLE, security
            },
            {
                name: 'Group 2 Summary',
                cat: ['Abia', 'Akwa-Ibom', 'Anambra', 'Cross Rivers', 'Delta', 'Edo', 'Enugu', 'Imo', 'Kaduna', 'Kano', 'Ogun', 'Oyo',],
                data: [40, 50, 55, 73, 44, 34, 67, 73, 44, 73, 44, 34,] //state project scope data data by project scope filter: overall, branch, FLE, security
            },
            {
                name: 'Group 3 Summary',
                cat: ['Adamawa', 'Bauchi', 'Bayelsa', 'Benue', 'Borno', 'Ebonyi', 'Ekiti', 'Gombe', 'Jigawa', 'Katsina', 'Kebbi', 'Kogi', 'Kwara', 'Nasarawa', 'Niger', 'Ondo', 'Osun', 'Plateau', 'Sokoto', 'Taraba', 'Yobe', 'Zamfara',],
                data: [40, 50, 55, 73, 44, 34, 67, 73, 44, 73, 44, 34, 40, 50, 55, 73, 44, 34, 67, 73, 44, 73,] //state project scope data data by project scope filter: overall, branch, FLE, security
            },
        ],
        groupTypes: [
            {
                name: 'LPA Assessment Type Summary',
                cat: ['Branch', 'FLE', 'Security'],
                data: makeGroupTypeData() //type project scope data by project scope filter: overall, branch, FLE, security
            },
            {
                name: 'Group 2 Assessment Type Summary',
                cat: ['Branch', 'FLE', 'Security'],
                data: makeGroupTypeData() //type project scope data data by project scope filter: overall, branch, FLE, security
            },
            {
                name: 'Group 3 Assessment Type Summary',
                cat: ['Branch', 'FLE', 'Security'],
                data: makeGroupTypeData() //type project scope data data by project scope filter: overall, branch, FLE, security
            },
        ],
    }

    // Set the data for the project overall scope coverage chart with auto makeProjectChartData()
    const [projectChart, setProjectChart] = useState(projectCampaignChartData.groups);
    useEffect(() => {
        if(projectChartFilter === 'groups'){
            makeGroupTypeData();
            setProjectChart(projectCampaignChartData.groups);
        }else if(projectChartFilter === 'types'){
            setProjectChart(projectCampaignChartData.types);
        }else if(projectChartFilter === 'zones'){
            setProjectChart(projectCampaignChartData.zones);
        }else { return null }
    }, [projectChartFilter]);


    // Set the data for the group type scope coverage chart with auto makeGroupTypeData()
    const [groupTypeScope, setGroupTypeScope] = useState(projectCampaignChartData.groupTypes[0]);
    useEffect(() => {
        /*if(projectScopeFilter === 'groupStates') {
            setgroupTypeScope(projectCampaignChartData.groupStates[showGroupNumber]);
        } else */
        if(projectScopeFilter === 'groupTypes') {
            setGroupTypeScope(projectCampaignChartData.groupTypes[showGroupNumber]);
        } else { return null };

    }, [projectScopeFilter, showGroupNumber]);




    return (
        <div className="flex flex-col w-full h-max bg-slate-100 p-4 items-center justify-start shadow">
            <h1 className="font-bold text-xl text-blue-900"> Admin Summary</h1>

{/**Campaign Overall Progress Bar*/}
            <section className="w-full h-max m-5 p-2 shadow border border-slate-200">
                <h1 className="self-start font-bold text-xl">Campaign Progress</h1>
                <ProgressBar progressPercentage={progressBarData()} />
            </section>

{/**Daily Scope Completion by Groups and Assessment Types */}
            <section className="w-full p-2 m-4 bg-white shadow-md rounded-lg">
                
                 <div className="flex w-full h-max bg-slate-100 p-4 items-center justify-between shadow">
                    <div>
                        <h1 className="self-start font-bold text-xl">Scope Completion (Daily)</h1>
                        <p className="text-slate-600">Date: <span className="text-slate-400">{todayString}</span></p>
                    </div>
                    <select onChange={handleChangeDailyScopeChart} className="w-max m-3 px-3 py-2 text-slate-600 focus:outline-none hover:border hover:border-slate-100 hover:shadow-md focus:border focus:border-slate-100 focus:shadow-md">
                        <option value="type">Assessment Types</option>
                        <option value="group">Groups</option>
                    </select>
                 </div>
                 
                <div className="flex flex-wrap p-6 w-full h-max justify-center">
                    {
                        dailyScopeChart.map((data, index) => {
                            return <DonutChart key={index} data={dailyScopeCoverage? makeDailyData()[index].data : [0, 0]} titleText={data.title} />
                        })
                    }
                </div>
            </section>

{/**Project Scope Overall by Groups and Assessment Types and Zone */}
            <section className="w-full p-2 m-4 bg-white shadow-md rounded ">
                <div className="flex w-full h-max bg-slate-100 px-4 py-7 items-center justify-between shadow">
                    <div>
                        <h1 className="font-bold text-xl">Project Scope Completion
                                (
                                <select onChange={handleChangeProjectChart} defaultValue={"types"} className="bg-inherit w-max text-slate-600 focus:outline-none hover:scale-101  hover:shadow-sm">
                                    <option value="groups" >Groups</option>
                                    <option value="types">Types</option>
                                    <option value="zones">Zones</option>
                                </select>
                                )
                        </h1>

                    </div>
                    {
                        projectChartFilter === 'groups'? (
                            <select onChange={handleProjectScopeFilter} defaultValue={"groupTypes"} className="w-max mx-3 px-3 py-2 text-slate-600 focus:outline-none hover:border hover:border-slate-100 hover:shadow-md focus:border focus:border-slate-100 focus:shadow-md">
                                {/*<option value='groupStates' disabled>States</option>*/}
                                <option value={'groupTypes'} >Assessment Types</option>
                            </select> 
                        ) 
                        :
                        null
                    }
                </div>
                
                <div className="flex flex-wrap self-start w-full p-6">
                    <div className={`${projectChartFilter === 'groups'? 'w-1/3' : 'w-full'} h-max pl-4 pt-4 shadow-md`}>
                        <ColumnChart
                            data={ makeProjectChartData() }
                            xaxis={ projectChart.xaxis } 
                            titleText={projectChart.titleText }
                            onClickHandler={handleSetGroupDetailChart}
                            clickableText={['LPA', 'Group 2', 'Group 3']}
                            horizontal={projectChartFilter !== 'groups'? false : true}
                        />
                    </div>
                    {
                        projectChartFilter === 'groups'? (
                            <div className="w-3/5 h-max pt-4 m-2 shadow-md">
                                <ColumnChart
                                    data={ makeGroupTypeData()}
                                    xaxis={ groupTypeScope.cat} 
                                    titleText={ groupTypeScope.name}
                                    horizontal={false}
                                />
                            </div>
                        ) : null
                    }
                </div>
            </section>
            
{/**Best and Worst Performing Evaluators */}
            <section className="w-full p-5 m-4 bg-white shadow-md rounded ">
                <h1 className="self-start font-bold text-xl">Best and Worst Performers</h1>
                <div className="flex flex-wrap self-center justify-content-center w-full p-6">
                    {/*<div className="w-2/5 h-max pt-4 m-2 shadow-md">
                        <ColumnChart
                        data={[98, 97, 97, 96, 96]} 
                        xaxis={['Abigail SS0345', 'Sheriff SE4367', 'Joshua SS3433', 'Temi SW3439', 'Uche SE3789']} 
                        titleText={'Top 5 performers'} 
                        horizontal={false}
                        />
                    </div>
                    <div className="w-2/5 h-max pt-4 m-2 shadow-md">
                        <ColumnChart
                        data={[25, 26, 34, 36, 36]} 
                        xaxis={['Diirin SW0345', 'Mopol SE7367', 'Keshia SS3833', 'Tefiama SW3939', 'Ogbo SE3009']} 
                        titleText={'Bottom 5 performers'} 
                        horizontal={false}
                        />
                </div>*/}
                </div>
            </section>

{/**zonal Scope Completion trend: Line graph */}
            <section className="w-full p-5 m-4 bg-white shadow-md rounded ">
                <h1 className="self-start font-bold text-xl">Scope coverage trend</h1>
                
                <LineChart
                    xaxis={pastWeekInDates.reverse()}
                    data={makeZonalTrendData()}
                />
            </section>
            
        </div>
    )
};

export default AdminSummary;