
import axios from 'axios';
import toast from 'react-hot-toast';

const baseURL = 'http://18.134.133.59:4000/api/v1.0';

export const signin = async (formData) => {
    try {
        toast.remove();
        toast.loading('Signing you in...');
        const { data } = await axios.post(`${baseURL}/admin/login`, formData);
        toast.remove();
        data.success ? toast.success('Login Success!', { duration: 7000 }) : toast.error('Login Failed! Try again');
        return data.success ? data : {token: false};
    } catch (error) {
        toast.remove();
        toast.error(error.message);
        return null;
    }
};

export const signinEvaluator = async (formData) => {
    try {
        toast.remove();
        toast.loading('Signing you in...');
        const { data } = await axios.post(`${baseURL}/evaluator/login`, formData);
        toast.remove();
        data.success ? toast.success('Login Success!', { duration: 7000 }) : toast.error('Login Failed! Try again');
        return data.success ? {...data, user: data.user[0]} : {token: false};
    } catch (error) {
        toast.remove();
        toast.error(error.message);
        return null;
    }
}; 
