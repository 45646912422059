import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { bulkUploadTaskThunk, createCampaignThunk, getAllCampaignsThunk, getDayTasksThunk } from "./corporateSlice";
import Table from "../../components/tables/Table";
import { useForm } from "react-hook-form";
import PaginatedTable from "../../components/tables/PaginatedTable";
import ReactHtmlTableToExcel from "react-html-table-to-excel";


const Corporate = () => {
    const { register, handleSubmit, errors } = useForm();
    const dispatch = useDispatch();
    const { allCampaigns, allTasks, dayTasks } = useSelector(state=> state.corporate);
    const date= new Date();
    const today= `${date.getFullYear()}-${date.getMonth()+1}-${date.getDate()}`;

    const tables= {
        campaign: {
            name: 'campaign',
            tHeaders: [ 'Campaign Title', 'Description', 'Branch Scope (Target)', 'Start Date', 'End Date' ],
            tProperties: [ 'campaign_name', 'campaign_description', 'total_branch', 'start_date', 'end_date']
        },
        task: {
            name: 'task',
            tHeaders: [ 'Campaign ID', 'Evaluator Code', 'Branch Code', 'Assigned Date', 'Task Specifity' ],
            tProperties: [ 'campaign_id', 'evaluator_access_code', 'branch_id', 'date_assigned', 'task_type']
        },
    }

    const [selectDate, setSelectDate] = useState(null);
    useEffect(()=>{
        dispatch(getDayTasksThunk(selectDate));
    }, [dispatch, selectDate])

    const handleDateChange = (e) => {
        setSelectDate(e.target.value);
    }

    const [formObj, setFormObj] = useState({});
    const handleFormChange = (e) => {
        const newForm= {...formObj};
        newForm[`${e.target.name}`] = e.target.value;
        setFormObj(newForm);
        console.log(formObj)
    }
    const handleFormSubmit = (e) => {
        e.preventDefault();
        dispatch(createCampaignThunk(formObj))
        .then(({ payload })=>{
            console.log({payload})
            if (payload.campaignCreateSuccess) setFormDisplay("hidden");
            return payload;
        })
        .then((res)=>{ 
            if (res.campaignCreateSuccess) dispatch(getAllCampaignsThunk());
        });
    }

    const [formDisplay, setFormDisplay] = useState("hidden");
    const showCreateCampaignForm = () => {
        // display form to create campaign
        setTaskFormDisplay("hidden");
        formDisplay === "hidden"? setFormDisplay("") : setFormDisplay("hidden");
    };

    const [taskFormDisplay, setTaskFormDisplay] = useState("hidden");

    const showTaskUploadForm = () => {
        setFormDisplay("hidden");
        taskFormDisplay === "hidden"? setTaskFormDisplay("") : setTaskFormDisplay("hidden");
    }

    const handleBulkUploadTask = (e) => {
        //dispatch(bulkUploadTaskThunk(e.taskFile));
        console.log(e.taskFile);
    }

    return (
        <div className="flex flex-col w-full h-max bg-slate-100 p-4 items-center justify-start shadow" >
            <h1 className="font-bold w-max mb-4 mx-auto text-xl text-blue-900">Corporate Affairs</h1>
            <p className="font-medium text-slate-500">
                Here you can <span className="text-blue-400"> Manage Campaign and Assign tasks in bulk </span>
            </p>
            <div className="flex flex-col my-4 flex-wrap w-full h-max justify-center">
                <div>
                    <button onClick={showCreateCampaignForm} className="m-2 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                        Create Campaign
                    </button>
                    <button onClick={showTaskUploadForm} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                        Bulk Upload Tasks
                    </button>
                </div>
            </div>
            
            <div className={`${formDisplay} flex flex-col w-2/3 p-2 m-4 bg-white shadow-md rounded-lg`}>
                <form id='createCampaignForm' onChange={handleFormChange} onSubmit={handleFormSubmit} className={`flex flex-col w-full h-max p-4 rounded border border-slate-300 self-center justify-center shadow-md ${formDisplay}`}>
                    <h2 className="self-center min-width-max font-medium text-lg text-blue-500">Create Campaign</h2>
                    <div className="flex flex-col my-3 lg:flex-row lg:justify-between my-3 w-full h-max lg:items-center justify-center">
                        <label htmlFor="campaign_name" className=" min-w-max text-md text-blue-700">Campaign Name:</label>
                        <input id="campaign_name" name="campaign_name" type="text" value={formObj.campaign_name? formObj.campaign_name: ""} placeholder="A title to know your campaign by . . ." className="w-full lg:w-3/5 h-max p-2 rounded border border-blue-100 text-blue-900 focus:outline-blue-300 hover:border-blue-300 hover:shadow-md" />
                    </div>
                    <div className="flex flex-col my-3 lg:flex-row lg:justify-between my-3 w-full h-max lg:items-center justify-center">
                        <label htmlFor="campaign_description" className=" min-w-max text-md text-blue-700">Campaign Description</label>
                        <textarea id="campaign_description" name="campaign_description" type="text" value={formObj.campaign_description? formObj.campaign_description: ""} placeholder="A little description about this campaign . . ." className="w-full lg:w-3/5 h-max p-2 rounded border border-blue-100 text-blue-900 focus:outline-blue-300 hover:border-blue-300 hover:shadow-md" />
                    </div>
                    <div className="flex flex-col my-3 lg:flex-row lg:justify-between my-3 w-full h-max lg:items-center justify-center">
                        <label htmlFor="total_branch" className=" min-w-max text-md text-blue-700">Scope:</label>
                        <input id="total_branch" name="total_branch" type="number" value={formObj.total_branch? formObj.total_branch: ""} placeholder="Number of Bank Branches to be Evaluated (Project Scope)" className="w-full lg:w-3/5 h-max p-2 rounded border border-blue-100 text-blue-900 focus:outline-blue-300 hover:border-blue-300 hover:shadow-md" />
                    </div>
                    <div className="flex flex-col my-3 lg:flex-row lg:justify-between my-3 w-full h-max lg:items-center justify-center">
                        <label htmlFor="start_date" className="min-w-max text-md text-blue-700">Start Date</label>
                        <input id="start_date" name="start_date" type="date" min={today} max='' placeholder="dd-mm-yyyy" value={formObj.start_date? formObj.start_date: ""} className="w-full lg:w-3/5 h-max p-2 rounded border border-blue-100 text-blue-900 focus:outline-blue-300 hover:border-blue-300 hover:shadow-md bg-inherit" />
                    </div>
                    <div className="flex flex-col my-3 lg:flex-row lg:justify-between my-3 w-full h-max lg:items-center justify-center">
                        <label htmlFor="end_date" className=" min-w-max text-md text-blue-700">Finish Date</label>
                        <input id="end_date" name="end_date" type="date" value={formObj.end_date? formObj.end_date: ""} className="w-full lg:w-3/5 h-max p-2 rounded border border-blue-100 text-blue-900 focus:outline-blue-300 hover:border-blue-300 hover:shadow-md bg-inherit" />
                    </div>
                    <input type='submit' value='Create Campaign' className="w-3/5 m-2 mx-auto hover:bg-blue-500 hover:text-white hover:scale-105 font-bold py-2 px-4 rounded border border-blue-500 text-blue-900" />
                </form>
            </div>

            <div className={`${taskFormDisplay} flex w-max p-2 m-4 bg-white shadow-md rounded-lg`}>
                <form className={` flex flex-col w-1/2 lg:w-1/2 p-4 rounded border border-slate-300 bg-300 justify-center shadow-md `} onSubmit={handleSubmit(handleBulkUploadTask)}>
                    <h1 className="text-blue-500 font-bold m-2 ml-0">Upload Tasks Mapping File</h1>
                    <input
                        {...register("taskFile")}
                        type="file"
                        className="border border-blue-500 rounded p-2"
                        accepted=".csv .xlsx .xls"
                        required
                    />
                    <select defaultValue={"DEFAULT"}
                        {...register("campaign_id")}
                     className="my-2 p-3 rounded bg-white border border-blue-500">
                        <option value="DEFAULT" disabled>Select Campaign</option>
                        {
                            allCampaigns? allCampaigns.map(campaign => {
                                return <option key={campaign.campaign_id} value={campaign.campaign_id}>{campaign.campaign_name}</option>
                            }) : null
                        }
                    </select>
                    <input className="rounded w-full p-3 m-2 mx-auto text-blue-600 bg-blue-100 hover:font-bold border border-blue-500 hover:outline hover:text-white hover:bg-blue-400 hover:outline-blue-500" type="submit" value="Upload" />
                </form>

                <div className="m-3">
                    <h1 className="text-blue-500 font-bold m-2 ml-0">Upload Directives</h1>
                    <p>1. Download Template File</p>
                    <p>2. Open and fill Excel file correctly</p>
                    <p>3. Upload task mapping file</p>
                    <p>4. Click Upload</p>
                    <ReactHtmlTableToExcel className="py-3 px-4 m-4 w-max h-max text-white bg-green-500" table={"templateTasks"} filename={"templateTasks"} sheet={"templateTasks"} buttonText={"Download Template File"} />
                </div>
                <table id="templateTasks" className="hidden">
                    <thead>
                        <tr>
                            {
                                ["Evaluator Code","Campaign ID", "Branch Code", "Assessment Date (YYYY-MM-DD)", "Assessment Type [All, FLE, Security, or [FLE, Security]]"].map(header => {
                                    return <th key={header}>{header}</th>
                                })
                            }
                        </tr>
                    </thead>
                </table>
            </div>

            <section className="w-full p-2 m-4 bg-white shadow-md rounded-lg">
                <h2 className="flex flex-wrap items-center text-lg font-bold text-blue-400 m-2">Tasks of Date: 
                    <input type='date' onChange={handleDateChange} defaultValue={today} placeholder='yyyy-mm-dd' className="w-1/4 h-max p-2 mx-2 rounded border border-blue-100 text-blue-900 focus:outline-blue-300 hover:border-blue-300 hover:shadow-md" />
                </h2>
                <div>
                    <PaginatedTable data={dayTasks} table={tables.task} pageLimit={5} dataLimit={10} />
                </div>
            </section>
            
            <section className="w-full p-2 m-4 bg-white shadow-md rounded-lg">
                <h2 className=" text-lg font-bold text-blue-400 m-2">Campaign List</h2>
                <div className="flex flex-col my-4 flex-wrap w-full h-max justify-center">
                    <PaginatedTable data={allCampaigns} table={tables.campaign} pageLimit={5} dataLimit={5} />
                </div>
            </section>

            {/*<h2 className=" text-lg font-bold text-blue-400 m-2">All Assigned Tasks</h2>
            <div className="flex flex-col overflow-x-scroll my-4 flex-wrap w-full h-max justify-center">
                <PaginatedTable data={allTasks} table={tables.task} pageLimit={5} dataLimit={10} />
                    </div>*/}

        </div>
    )
};

export default Corporate;