import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import PaginatedTable from '../../components/tables/PaginatedTable';
import Table from '../../components/tables/Table';


const Branch = () => {
    const branches = useSelector(state => state.branches)
    const table = {
        title : 'Branches',
        tHeaders : [ 'Branch', 'Address', 'Code', 'Bank', 'Group', 'State', 'Zone' ],
        tProperties : [ 'name', 'address', 'code', 'bank', 'group', 'state', 'zone' ],
    }

    const [filteredBranches, setFilteredBranches] = useState(branches)

    const [ searchTerm, setSearchTerm] = useState('');
    const handleSearchFilter = (e) => {
        setSearchTerm(e.target.value);
    }

    const [ groupFilterTerm, setGroupFilterTerm ] = useState('');
    const handleGroupFilter = (e) => {
        setGroupFilterTerm(e.target.value);
    }

    const [ bankFilterTerm, setBankFilterTerm ] = useState('');
    const handleBankFilter = (e) => {
        setBankFilterTerm(e.target.value);
    }

    useEffect(()=>{
        const ST = searchTerm.toLowerCase();
        const GFT = groupFilterTerm.toLowerCase();
        const BFT = bankFilterTerm.toLowerCase();

        if (ST.length && !GFT.length && !BFT.length) {
            setFilteredBranches(branches.filter(branch => branch.name.toLowerCase().includes(ST) || branch.address.toLowerCase().includes(ST) || branch.code.toLowerCase().includes(ST)/* || branch.bank.toLowerCase().includes(ST) || branch.group.toLowerCase().includes(ST) || branch.state.toLowerCase().includes(ST)*/))
        }
        else if (ST.length && GFT.length && !BFT.length) {
            setFilteredBranches(branches.filter(branch => (branch.name.toLowerCase().includes(ST) || branch.address.toLowerCase().includes(ST) || branch.code.toLowerCase().includes(ST)) && branch.group.toString() === GFT))
        }
        else if (ST.length && !GFT.length && BFT.length) {
            setFilteredBranches(branches.filter(branch => (branch.name.toLowerCase().includes(ST) || branch.address.toLowerCase().includes(ST) || branch.code.toLowerCase().includes(ST)) && branch.bank.toLowerCase() === BFT))
        }
        else if (!ST.length && GFT.length && !BFT.length) {
            setFilteredBranches(branches.filter(branch => branch.group.toString() === GFT))
        }
        else if (!ST.length && GFT.length && BFT.length) {
            setFilteredBranches(branches.filter(branch => branch.group.toString() === GFT && branch.bank.toLowerCase() === BFT))
        }
        else if (!ST.length && !GFT.length && BFT.length) {
            setFilteredBranches(branches.filter(branch => branch.bank.toLowerCase() === BFT))
        }
        else if (ST.length && GFT.length && BFT.length) {
            setFilteredBranches(branches.filter(branch => (branch.name.toLowerCase().includes(ST) || branch.address.toLowerCase().includes(ST) || branch.code.toLowerCase().includes(ST)) && branch.group.toString() === GFT && branch.bank.toLowerCase() === BFT))
        }
        else if (!ST.length && !GFT.length && !BFT.length) {
            setFilteredBranches(branches)
        } else { setFilteredBranches(branches) }


    }, [bankFilterTerm, branches, groupFilterTerm, searchTerm])
    

    return (
        <div className='flex flex-col'>
            <h1 className="font-bold text-xl text-blue-800 mx-auto my-4">View {table.title}</h1>
            <div className='flex shadow'>
                <div className="flex flex-col rounded border shadow items-center w-1/3 p-4 m-2">
                    <h1 className='font-bold text-blue-600'>Branches</h1>
                    <p className='font-bold text-xl text-slate-700'>{branches.length}</p>
                </div>
                <div className="flex flex-col rounded border shadow items-center w-1/3 p-4 m-2">
                    <h1 className='font-bold text-blue-600'>Banks</h1>
                    <p className='font-bold text-xl text-slate-700'>{4}</p>
                </div>
            </div>
            <div className='flex flex-col border border-slate-100 shadow'>
                <div className="w-full m-1">
                    <input type='text' onChange={handleSearchFilter} className="w-1/3 m-3 px-3 py-2 rounded-full border border-slate-300 focus:outline-blue-400" placeholder="Search Branch Code, Branch Name" />
                    <select onChange={handleBankFilter} defaultValue={""} className="w-1/6 m-3 px-3 py-2 rounded border border-slate-300 focus:outline-blue-400 ">
                        <option value="">All Banks</option>
                        <option value="Access">Access</option>
                        <option value="GTB">GTB</option>
                        <option value="Stanbic">Stanbic</option>
                        <option value="Zenith">Zenith</option>
                    </select>

                    <select onChange={handleGroupFilter} defaultValue={""} className="w-1/6 m-3 px-3 py-2 rounded border border-slate-300 focus:outline-blue-400 ">
                        <option value="" >All Groups</option>
                        <option value="1">LPA</option>
                        <option value="2">Group 2</option>
                        <option value="3">Group 3</option>
                    </select>
                </div>
            </div>
            <PaginatedTable
                data={filteredBranches}
                table={table}
                dataLimit={14}
                pageLimit={10}
            />
            {/*<Table table={table} data={branches} />*/}
        </div>
    )
}

export default Branch;