import axios from "axios";
import toast from "react-hot-toast";
import { today } from "../report/Report";

const baseURL = 'http://18.134.133.59:4000/api/v1.0';

export const fetchFlaggedReports = async (date= today, token) => {
    try {
        const { data: flaggedEntries } = await axios.get(`${baseURL}/flag/flagged-branches/${date}?campaign_id=2`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        const flagged = flaggedEntries;
        console.log({ flagged });

        return {
            branches: flagged.flaggedBranches,
            entries: flagged.allflag,
        }
    } catch(error) {
        toast(error.message)
    }
}

export const unflagReport = async (token, flagID) =>{
    try {
        const {data: response} = await axios.post(`${baseURL}/flag/delete/${flagID}`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        console.log({response})
        return toast.success(response.msg);
    } catch(e){
        toast.error(e.message);
    }
}

export const acceptFlagReport = async (token, flagID) =>{
    try {
        const {data: response} = await axios.post(`${baseURL}/flag/approve/${flagID}`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        console.log({response})
        return toast.success(response.msg);
    } catch(e){
        toast.error(e.message);
    }
}