import { configureStore } from '@reduxjs/toolkit';
import dataSummaryReducer from '../features/adminSummary/summarySlice';
import evaluatorListReducer from '../features/evaluator/evaluatorSlice';
import branchListReducer from '../features/branch/branchSlice';
import reportReducer from '../features/report/reportSlice';
import adminAuthReducer from '../features/authentication/authSlice';
import corporateReducer from '../features/corporate/corporateSlice';
import flaggedIssuesReducer from '../features/flaggedIssues/flaggedIssuesSlice';

export const store = configureStore({
  reducer: {
    auth: adminAuthReducer,
    dataSummary: dataSummaryReducer,
    evaluators: evaluatorListReducer,
    branches: branchListReducer,
    reports: reportReducer,
    corporate: corporateReducer,
    flagged: flaggedIssuesReducer,
  },
  devTools: false,
});
