import axios from "axios";
import toast from "react-hot-toast";

const baseURL = 'http://18.134.133.59:4000/api/v1.0';

export const createCampaign = async (form, token) => {
    try{
        const { data } = await axios.post(`${baseURL}/campaign/create`, form, {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        });
        toast.loading("Creating Campaign, Please Wait ...");
        if (data) toast.remove();
        data.success? toast.success(data.msg) : toast.error(data.msg);
        return data;
    } catch (error) {
        toast.remove()
        toast.error(error.message);
    }
}

export const getAllCampaigns = async (token) => {
    const { data } = await axios.get(`${baseURL}/campaign/all`, {
        headers: {
            Authorization: `Bearer ${token}`,
        }
    });
    return data;
}

export const getAllTasks = async (token) => {
    const { data } = await axios.get(`${baseURL}/campaign/assessment/scope/list?campaign_id=2`, {
        headers: {
            Authorization: `Bearer ${token}`,
        }
    });
    return data;
}

export const getDayTasks = async (token, date) => {
    const { data } = await axios.get(`${baseURL}/campaign/assessment/scope/list/${date}?campaign_id=2`, {
        headers: {
            Authorization: `Bearer ${token}`,
        }
    });

    console.log({ dailyTasksAPI : data })
    return data;
}

export const bulkAssignTasks = async (formData, token) => {
    return;
}