import axios from "axios";
import toast from "react-hot-toast";

const baseURL = 'http://18.134.133.59:4000/api/v1.0';

export const fetchEvaluatorList = async (token) =>{
    const { data } = await axios.get(`${baseURL}/evalutor-list?campaign_id=2`, {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    });
    const evaluatorList = data.Evaluator.map(evaluator => {
        return {
            id: evaluator.evaluator_id,
            access_code: evaluator.evaluator_access_code,
            full_name: evaluator.evaluator_name,
            email: evaluator.evaluator_email,
            image: evaluator.evaluator_image,
            address: evaluator.evaluator_address,
            lga: evaluator.evaluator_lga,
            zone: evaluator.evaluator_zone,
            state: evaluator.evaluator_state,
            status: evaluator.account_status,
            createdAt: evaluator.date_created
        }
    })

    return evaluatorList.reverse();
};

export const assignTask = async (data, token) => {
    try {const { data: response } = await axios.post(`${baseURL}/evaluator/assessment/assign?campaign_id=2`, { task: data }, {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    });
    console.log({ response })
    response && response.success? toast.success(response.msg): toast.error(response.msg);
    return response;
    } catch (error) {
        toast.error(error.message);
        return null;
    }
}

export const viewEvaluatorDetails = async (token, evaluator_code) => {
    try {
        const { data: evaluatorTasks } = await axios.get(`${baseURL}/campaign/assessment/scope/evaluator/${evaluator_code}`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        return {
            tasks: evaluatorTasks,
        };
    } catch (error) {
        toast.error(error.message);
        return null;
    }
}