//import { PersonRounded } from '@material-ui/icons';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { signOutThunk } from '../../features/authentication/authSlice';
//import Logo from '../../images/TestAssessify.png';

const EvaluatorHeader = () => {
	const dispatch= useDispatch();	
	const { evaluator } = useSelector(state=> state.auth);

	const handleLogout= (e) =>{
		dispatch(signOutThunk());
		sessionStorage.removeItem('token');
		sessionStorage.removeItem('evaluator_token');
		console.log(sessionStorage.getItem('token'));
		console.log(sessionStorage.getItem('evaluator_token'));
	}

	return (
		<div className="w-full h-10 bg-white sticky top-0 z-40 shadow-md">
			<div className="h-full px-2 py-1 flex items-center justify-between">
				<div className="h-full">
					<img src={null} alt="Logo" className="w-36 h-full object-contain" />
				</div>
				<div className="flex w-max items-center">
					<button onClick={handleLogout} className="bg-slate-700 hover:bg-red-900 px-2 py-1 rounded text-white capitalize text-sm">
						log out
					</button>
				</div>
			</div>
		</div>
	);
};

export default EvaluatorHeader;
