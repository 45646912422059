import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
/*import {
	ChevronRightRounded,
	KeyboardArrowDownRounded
} from '@material-ui/icons';*/
import AdminHeader from '../components/AdminHeader/AdminHeader';
import AdminSidebar from '../components/AdminSidebar/AdminSidebar';
import Branch from '../features/branch/Branch';
import Evaluator from '../features/evaluator/Evaluator';
import AdminSummary from '../features/adminSummary/AdminSummary';
import Reports, { today } from '../features/report/Report';

import { evaluatorListThunk } from '../features/evaluator/evaluatorSlice';
import { branchListThunk } from '../features/branch/branchSlice';
import { reportsThunk } from '../features/report/reportSlice';
import Corporate from '../features/corporate/Corporate';
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import { getAllCampaignsThunk, getAllTasksThunk } from '../features/corporate/corporateSlice';
import { dailyScopeCoverageThunk, pastWeekZoneScopeTrendThunk, overallScopeCoverageThunk } from '../features/adminSummary/summarySlice';
import FlaggedIssues from '../features/flaggedIssues/FlaggedIssues';

const pages = [
	{
		id: 0,
		title: 'Summary',
		// icon: <HomeRounded />,
		page: <AdminSummary />,
	},
	{
		id: 1,
		title: 'Manage Reports',
		// icon: <HomeRounded />,
		page: <Reports />,
	},
	{
		id: 2,
		title: 'Corporate Affairs',
		// icon: <CollectionsBookmarkRounded />,
		page: <Corporate />,
	},
	{
		id: 3,
		title: 'Manage Evaluators',
		// icon: <CollectionsBookmarkRounded />,
		page: <Evaluator />,
	},
	{
		id: 4,
		title: 'View Bank Branches',
		// icon: <AccountBoxRounded />,
		page: <Branch />,
	},
	{
		id: 5,
		title: 'Manage Flagged Issues',
		// icon: <AccountBoxRounded />,
		page: <FlaggedIssues />,
	},
];

const AdminDashboard = () => {
	const navigation = useNavigate();
	const auth = useSelector((state) => state.auth);

	const dispatch = useDispatch();
	useEffect(()=>{
		dispatch(dailyScopeCoverageThunk(today));
		dispatch(overallScopeCoverageThunk());
		//dispatch(pastWeekZoneScopeTrendThunk());
		dispatch(evaluatorListThunk());
		dispatch(branchListThunk());
		dispatch(reportsThunk());
		dispatch(getAllTasksThunk());
		dispatch(getAllCampaignsThunk());
	})

	useEffect(()=>{
		const token = sessionStorage.getItem('token') ? sessionStorage.getItem('token') : false;
		//const { token } = auth;
		if (!token) {
			navigation('/');
			toast.remove();
			toast('We have logged you out');
		}
	}, [navigation, auth]);


    // get value from session storage, if null default to 0
	const [currentPageID, setCurrentPageID] = useState(
		sessionStorage.getItem('admPage') ? Number(JSON.parse(sessionStorage.getItem('admPage'))) : 0
	);

	const setSelectedPageID = (id) => {
		setCurrentPageID(id);
		// persist selected page in session storage
		sessionStorage.setItem('admPage', id);
	};

	return (
		<div>
			<AdminHeader />
			<div className="flex h-full" style={{ height: '100%'}}>
				<AdminSidebar className='h-full' pages={pages} setPage={setSelectedPageID} currentPage={currentPageID} />
				<div style={{ flex: '4' }} className="overflow-x-hidden m-4">
					{/* map through pages and only render page that matches id */}
					{pages.map((page) =>
						page.id === currentPageID ? (
							<div key={page.title} className="max-w-full">
								{page.page}
							</div>
						) : null
					)}
				</div>
			</div>
		</div>
	);
};

export default AdminDashboard;