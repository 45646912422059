import { useEffect, useState } from 'react';
import ReactHtmlTableToExcel from 'react-html-table-to-excel';
import { useDispatch, useSelector } from 'react-redux';
import PaginatedTable from '../../components/tables/PaginatedTable';
import Table from '../../components/tables/Table';
import { assignTaskThunk } from './evaluatorSlice';

const Evaluator = () => {
    const dispatch = useDispatch();
    const evaluators = useSelector(state => state.evaluators);
    const { allCampaigns } = useSelector(state=> state.corporate);
    const branches = useSelector(state => state.branches);
    
    const activeEvaluators = evaluators.filter(evaluator => evaluator.status === 'active');


    const table = {
        title : 'Evaluators',
        tHeaders : [ 'Access code', 'Name', 'Email', 'Address', 'LGA', 'Zone', 'State', 'Status', 'Created At', 'Action', ],
        tProperties : [  'access_code', 'full_name', 'email', 'address', 'lga', 'zone', 'state', 'status', 'createdAt',  ],
    }

    const [taskFormDisplay, setTaskFormDisplay] = useState("hidden");
    const handleClickAssignTask = (record) => {
        setTaskFormDisplay("");
        setTaskFormObject({...taskFormObject, evaluator_access_code: record.access_code, evaluator_name: record.full_name});
        console.log({record})

    }

    const [profileCardDisplay, setProfileCardDisplay] = useState("hidden");
    const [profileCardObject, setProfileCardObject] = useState({});
    const handleClickView = (record) => {
        setProfileCardDisplay("");
        console.log({record})
        setProfileCardObject({ ...record });
        console.log({profileCardObject})
    }



    const [taskFormObject, setTaskFormObject] = useState({});
    const handleOnChangeTaskForm = (e) => {
        setTaskFormObject({ ...taskFormObject, [e.target.name]: e.target.value })
    }

    const handleSubmitTaskForm = (e) => {
        e.preventDefault();
        if (!taskFormObject.campaign_id) { taskFormObject.campaign_id = allCampaigns[allCampaigns.length -1].campaign_id; }
        if (!taskFormObject.task_type) {taskFormObject.task_type = "All";}
        const copyObject = { ...taskFormObject, branch_id: taskFormObject.branch1_id };
        const copyObject2 = taskFormObject.branch2_id? { ...taskFormObject, branch_id: taskFormObject.branch2_id } : null;
        taskFormObject.branch2_id? dispatch(assignTaskThunk([copyObject, copyObject2])).then(({payload})=> {
            return payload.success? setTaskFormDisplay("hidden") : null;
        })
        : dispatch(assignTaskThunk([copyObject])).then(({ payload })=> {
            return payload.success? setTaskFormDisplay("hidden") : null;
        });
    }



    //FILTER LOGIC
    const [filteredEvaluators, setFilteredEvaluators] = useState(evaluators);

    const [searchTerm, setSearchTerm] = useState('');
    const handleSearchFilter = (e) => {
        setSearchTerm(e.target.value);
    }

    const [zoneFilterTerm, setZoneFilterTerm] = useState('');
    const handleZoneFilter = (e) => {
        setZoneFilterTerm(e.target.value);
    }

    useEffect(() => {
        const ST = searchTerm.toLowerCase();
        const ZFT = zoneFilterTerm.toLowerCase();

        if(ST.length && !ZFT.length) {
            setFilteredEvaluators(evaluators.filter(evaluator => evaluator.full_name.toLowerCase().includes(ST) || evaluator.access_code.toLowerCase().includes(ST)));
        } 
        else if(!ST.length && ZFT.length) {
            setFilteredEvaluators(evaluators.filter(evaluator => evaluator.zone.toLowerCase() === ZFT));
        }
        else if(ST.length && ZFT.length) {
            setFilteredEvaluators(evaluators.filter(evaluator => (evaluator.full_name.toLowerCase().includes(ST) || evaluator.access_code.toLowerCase().includes(ST)) && evaluator.zone.toLowerCase() === ZFT));
        }
        else if(!ST.length && !ZFT.length) {
            setFilteredEvaluators(evaluators);
        }
        else { setFilteredEvaluators(evaluators); }

    }, [evaluators, searchTerm, zoneFilterTerm])




    return (
        <div className='flex flex-col '>
            <h1 className="font-bold text-xl text-blue-800 mx-auto my-4">Evaluators List</h1>
            {/*<div className='flex justify-around'>
                <button className="bg-blue-800 text-white px-4 py-2 rounded-lg mx-auto my-4">Bulk Upload Evaluators</button>
                <button className="bg-blue-800 text-white px-4 py-2 rounded-lg mx-auto my-4"> Upload New Evaluator</button>
            </div>*/}
            <div className='flex shadow'>
                <div className="flex flex-col rounded border shadow items-center w-1/3 p-4 m-2">
                    <h1 className='font-bold text-blue-600'>Total Evaluators</h1>
                    <p className='font-bold text-xl text-slate-700'>{evaluators.length}</p>
                </div>
                <div className="flex flex-col rounded border shadow items-center w-1/3 p-4 m-2">
                    <h1 className='font-bold text-blue-600'>Active Evaluators</h1>
                    <p className='font-bold text-xl text-slate-700'>{activeEvaluators.length}</p>
                </div>
            </div>
            <div className='flex flex-col border border-slate-100 shadow'>
                <div className="w-full m-1">
                    <input type='text' onChange={handleSearchFilter} className="w-1/3 m-3 px-3 py-2 rounded-full border border-slate-300 focus:outline-blue-400" placeholder="Search Evaluator Name or Code" />
                    <select onChange={handleZoneFilter} className="w-1/6 m-3 px-3 py-2 rounded border border-slate-300 focus:outline-blue-400 ">
                        <option value="">Zones</option>
                        <option value="SW">South West</option>
                        <option value="SE">South East</option>
                        <option value="SS">South South</option>
                        <option value="NE">North East</option>
                        <option value="NW">North West</option>
                        <option value="NC">North Central</option>
                    </select>
                    <ReactHtmlTableToExcel className={"p-2 text-white bg-green-500"} table={"evaluatorsTable"} buttonText={"Download Table"} filename="Download_flagged_entries" sheet="flagged_entries_xls" />
                    
                    <Table table={table} hidden={true} data={ filteredEvaluators } id={"evaluatorsTable"} />
                {/*
                    <select className="w-1/6 m-3 px-3 py-2 rounded border border-slate-300 focus:outline-blue-400 ">
                        <option>Performance</option>
                        <option>Poor performers (less than 60%)</option>
                        <option>Performers (more than 60%)</option>
                        <option>Super performers (more than 95%)</option>
                    </select>
                */}
                </div>

                <PaginatedTable isAction={true} actionElements={[/*{text: "Details", onClick: handleClickView},*/ {text: "Assign Task", onClick: handleClickAssignTask}]} data={filteredEvaluators} table={table} dataLimit={10} pageLimit={10} />

                {/**Hidden Task Assignment form and Hidden Profile Card */}
                <div className={`flex flex-col fixed w-screen h-screen top-0 bg-black opacity-90 justify-center items-center ${taskFormDisplay}`}>
                    <form onChange={handleOnChangeTaskForm} onSubmit={handleSubmitTaskForm} className={`flex flex-col w-2/4 lg:w-1/4 rounded opacity-100 p-3 bg-white border border-blue-400 shadow-md ${taskFormDisplay}`}>
                        <div className='flex w-full px-2 justify-between items-center bg-blue-700'>
                            <h2 className='text-white font-bold text-sm'>{ taskFormObject.evaluator_name }</h2>
                            <button onClick={(e)=>{e.preventDefault(); setTaskFormDisplay("hidden")}} className="bg-white text-red-600 font-bold p-2 py-1 rounded-full my-4 hover:bg-red-500 hover:text-white">X</button>
                        </div>

                        <label className='flex flex-col font-bold text-blue-900 font-md'>
                            <span className="font-bold text-blue-900 my-2">Campaign</span>
                            <select name='campaign_id' className='w-full p-2 rounded border border-slate-300' required>
                                <option disabled>Select Campaign</option>
                                {
                                    allCampaigns? allCampaigns.map(campaign => {
                                        return <option value={campaign.campaign_id}>{campaign.campaign_name}</option>
                                    }) : `Loading...`
                                }
                            </select>
                        </label>
                        <label className='flex flex-col font-bold text-blue-900 font-md'>
                            <span className="font-bold text-blue-900 my-2">Branch 1</span>
                            <input type="text" name='branch1_id' list='branches' className='w-full rounded border p-2 border-slate-300' required/>
                            <datalist id='branches'>
                                <option value=''>Branch Name</option>
                                {
                                    branches.map(branch => {
                                        return <option value={branch.name}>{`${branch.name}`}</option>
                                    })
                                }
                            </datalist>
                        </label>
                        <label className='flex flex-col font-bold text-blue-900 font-md'>
                            <span className="font-bold text-blue-900 my-2">Branch 2</span>
                            <input type="text" name='branch2_id' list='branches' className='w-full rounded border p-2 border-slate-300'/>
                            <datalist id='branches'>
                                <option value=''>Branch Name</option>
                                {
                                    branches.map(branch => {
                                        return <option value={branch.name}>{`${branch.name}`}</option>
                                    })
                                }
                            </datalist>
                        </label>
                        <label className='flex flex-col font-bold text-blue-900 font-md'>
                            <span className="font-bold text-blue-900 my-2">Assessment Date</span>
                            <input type="date" placeholder="dd-mm-yyyy" name='date_assigned' className='w-full rounded p-2 border border-slate-300' required/>
                        </label>
                        <label className='flex flex-col font-bold text-blue-900 font-md'>
                            <span className="font-bold text-blue-900 my-2">Assessment Type</span>
                            <select name='task_type' className='w-full rounded p-2 border border-slate-300' required>
                                {
                                    [ 'All', 'FLE', 'Security', 'FLE and Security' ].map(type => {
                                        return <option value={type}>{type}</option>
                                    })
                                }
                            </select>
                        </label>
                        <input type="submit" value="Assign Task" className='w-full bg-blue-800 text-white p-2 rounded-lg mx-auto ml-1 my-4 hover:outline hover:outline-blue-600 hover:bg-blue-500'/>
                    </form>
                </div>
                
                <div className={`flex flex-col fixed w-3/4 h-screen top-0 bg-white opacity-95 ml-4 md:ml-6 lg:ml-10 justify-center items-center ${profileCardDisplay}`}>
                    <div className={`flex flex-col w-full lg:w-1/2 rounded p-4 bg-blue-300 border border-blue-400 shadow-md ${profileCardDisplay}`}>
                        <div className='flex w-full bg-blue-900 justify-between items-center'>
                            <h2 className='text-white font-bold ml-3'>{ profileCardObject.full_name }</h2>
                            <button onClick={(e)=>{e.preventDefault(); setProfileCardDisplay("hidden")}} className="bg-blue-800 text-white p-2 py-1 rounded-lg mr-4 my-4 hover:bg-red-500">X</button>
                        </div>
                        <div className='flex flex-col items-start w-full bg-blue-900'>
                            {
                                ['Campaign Tasks', 'Campaign Entries', 'Average Performance'].map(heading => {
                                    return <h2 className='text-white font-md w-max ml-3'>{ heading }: <span>{100}</span></h2>
                                })
                            }
                        </div>
                    </div>
                </div>
                
            </div>
        </div>
    )
}

export default Evaluator;