/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
//import Table from "../../components/tables/Table";
import { reportsThunk, unflagReportThunk } from "./reportSlice";
import PaginatedTable from "../../components/tables/PaginatedTable";
import toast from "react-hot-toast";
import ReactHtmlTableToExcel from "react-html-table-to-excel";
import Table from "../../components/tables/Table";


const date= new Date();
const month = (date.getMonth()+1) > 9? date.getMonth()+1: (`0${date.getMonth() + 1}`);
const day = date.getDate() > 9? date.getDate() : `0${date.getDate()}`;
export const today= `${date.getFullYear()}-${month}-${day}`;

const Reports = () => {
    const dispatch= useDispatch();
    const entries = useSelector(state => state.reports.entries);
    const reports = useSelector(state => state.reports);
    //const evaluators = useSelector(state => state.evaluators);
    const branches = useSelector(state => state.branches);
    //const yesterday= new Date(date.setDate(date.getDate()-7));

    const table = {
        overview_assessment: {
            name: 'overview_assessment',
            tHeaders: [ 'Evaluator Code', 'Bank Name', 'Assessment Type', 'Branch Code', 'Score' ],
            tProperties: [ 'evaluator_code', 'bank_name', 'type', 'branch_id', 'score']
        },
        branch_assessment: {
            name: 'branch_assessment',
            tHeaders: [ 'Evaluator Code', 'Bank Name', 'Branch Code', /*'Branch Address', 'Branch State', 'Branch Zone',*/ 'Entry Experience', 'Ambience', 'Service', 'ATM', 'Total Score', 'Assessment Type', 'Total Score (%)' ],
            tProperties: [ 'evaluator_code', 'bank_name','branch_id', /*'branch_address', 'branch_state', 'branch_zone',*/ 'Entry', 'branchAmbience', 'services', 'atm', 'totalScore', 'assessment_type', 'totalScorePercentage']
        },
        fle_assessment: {
            name: 'fle_assessment',
            tHeaders: [ 'Evaluator Code', 'Bank Name', 'Branch Code', /*'Staff ID', 'Surname', 'Firstname', 'Validated Surname?', 'Gender', 'Staff Role', 'Branch Code', 'Branch Address', 'Branch State', 'Zone',*/ 'Appearance', 'Behaviour', 'Service', 'Product Knowledge', 'Total Score', 'Assessment Type', 'Total Score (%)' ],
            tProperties: [ 'evaluator_code', 'bank_name', 'branch_id', /*'staff_id', 'surname', 'firstname',  'validated_surname',  'gender', 'staff_role', 'branch_id', 'branch_address', 'branch_state', 'zone',*/ 'appearance', 'behaviour', 'services', 'product_knownledge', 'totalScore', 'assessment_type', 'totalScorePercentage']
        },
        security_assessment: {
            name: 'security_assessment',
            tHeaders: [ 'Evaluator Code', 'Bank Name', 'Branch Code', /*'Branch Group', 'Staff ID', 'Surname', 'Firstname', 'Validated Surname?', 'Gender', 'Job Role', 'Branch Address', 'Branch State', 'Zone',*/ 'Welcome Greeting', 'Appearance', 'Behaviour', 'Total Score', 'Assessment Type', 'Total Score (%)' ],
            tProperties: [ 'evaluator_code', 'bank_name', 'branch_id', /*'branch_group', 'staff_id', 'surname', 'firstname',  'validated_surname',  'gender', 'job_role', 'branch_address', 'branch_state', 'zone',*/ 'welcome_greeting', 'appearance', 'behaviour', 'totalScore', 'assessment_type', 'totalScorePercentage']
        },
    }

    const [currentTable, setCurrentTable] = useState({...table.overview_assessment});
    const handleSetTable = (e) => {
        const currentDiv= document.getElementById(`${e.target.innerText.toLowerCase()}_assessment`);
        const siblings= [...currentDiv.parentElement.children];
        siblings.forEach(child => {child.style.transform = 'scale(0.9)'});

        currentDiv.style.transform= 'scale(1)';
        table[`${e.target.innerText.toLowerCase()}_assessment`]? setCurrentTable(table[`${e.target.innerText.toLowerCase()}_assessment`]) : setCurrentTable(table.overview_assessment);
    }

    const [currentEntries, setCurrentEntries] = useState(entries? entries.overview : []);
    useEffect(() => {
        if (entries && currentTable.name === "overview_assessment") {
            setCurrentEntries(entries.overview);
        }
        if (entries && currentTable.name === "branch_assessment") {
            setCurrentEntries(entries.branch);
        }
        if (entries && currentTable.name === "fle_assessment") {
            setCurrentEntries(entries.fle);
        }
        if (entries && currentTable.name === "security_assessment") {
            setCurrentEntries(entries.security);
        }
    }, [currentTable, entries]);
    
    //const [reportDate, setReportDate] = useState(today);
    
    //console.log(reportDate.length? reportDate: today);
    const handleDateSelect = (e) => {
        dispatch(reportsThunk(e.target.value));
    };


    const handleUnflagReport = (report) => {
        dispatch(unflagReportThunk(report.flag_id))
        //const flagBearer= evaluators.find(evaluator => evaluator.access_code === report.Evaluator_id);
        //console.log({ flagBearer });
        //toast.remove();
        //toast(`Evaluator is ${flagBearer.full_name} in ${flagBearer.state} state under the ${flagBearer.zone} zone.`, { duration: 10000 });
    }


    const [searchTerm, setSearchTerm] = useState('');
    const handleFilterSearch = (e) => { setSearchTerm(e.target.value) };

    const [groupFilterTerm, setGroupFilterTerm] = useState('');
    const handleFilterGroup = (e) => { setGroupFilterTerm(e.target.value) };

    const [performanceFilterTerm, setPerformanceFilterTerm] = useState('');
    const handleFilterPerformance = (e) => { setPerformanceFilterTerm(e.target.value) };


    

    const [currentEntriesFiltered, setCurrentEntriesFiltered] = useState(currentEntries);
    //console.log({currentEntries});
    //console.log({currentEntriesFiltered});
    useEffect(() => {
        const ST = searchTerm.toLowerCase();
        const GT = groupFilterTerm.toLowerCase();
        const PT = performanceFilterTerm.toLowerCase();

        const getBranchGroup = (branchCode) => {
            const branch= branches.find(branch=> branch.code === branchCode)
            return branch? `${branch.group}` : '';
        }
        console.log(getBranchGroup('BK01'));

        if( ST.length && !GT.length ) { 
            const filtered = currentEntries.filter(entry => {
                return entry.evaluator_code.toLowerCase().includes(ST) || entry.branch_id.toLowerCase().includes(ST)
            })
            setCurrentEntriesFiltered(filtered);
        }
        else if( ST.length && GT.length) { 
            const filtered = currentEntries.filter(entry => {
                console.log(getBranchGroup(entry.branch_id))
                return (entry.evaluator_code.toLowerCase().includes(ST) || entry.branch_id.toLowerCase().includes(ST)) && 
                (getBranchGroup(entry.branch_id).toLowerCase() === GT)
            })
            setCurrentEntriesFiltered(filtered);
        }
        else if( !ST.length && GT.length) { 
            const filtered = currentEntries.filter(entry => {
                return getBranchGroup(entry.branch_id).toLowerCase() === GT;
            })
            setCurrentEntriesFiltered(filtered);
        }
        else { setCurrentEntriesFiltered(currentEntries) }


    }, [entries, currentEntries, searchTerm, groupFilterTerm/*, performanceFilterTerm*/]);





    return (
        <div className="flex flex-col items-center">
            <h1 className="font-bold text-xl text-blue-800">Manage Reports</h1>
            <section className="w-full p-5 m-4 bg-white border border-slate-200 shadow-md rounded ">
                
                <div className="flex items-end">
                    <h1 className="self-start font-bold text-lg text-blue-700">Evaluators Entries: </h1>
                    <select onChange={handleDateSelect} className="font-medium text-blue-500 mx-3 pb-0.5 text-sm focus:outline-none hover:border hover:border-slate-100 hover:shadow-md focus:border focus:border-slate-100 focus:shadow-md">
                        <option value={today} selected> Today </option>
                        {
                            reports.dates? reports.dates.map(date => {
                                return <option key={date} value={date}>{date}</option>
                            }) : null
                        }
                    </select>

                </div>

                {
                    reports.totalEvaluators? (
                        <div className="flex flex-wrap border shadow border-slate-100">
                            <div className="flex flex-col h-max w-max p-2 m-1 items-center justify-center border border-slate-200 rounded shadow">
                                <h1 className="text-md font-bold my-1 text-blue-600">Summary</h1>
                                <div className="flex flex-wrap">
                                    <div className="flex flex-col h-max w-max p-1 m-1 items-center justify-center space-x-between">
                                        <h1>Evaluators</h1>
                                        <h1>{reports.totalEvaluators? reports.totalEvaluators : 0}</h1>
                                    </div>
                                    <div className="flex flex-col h-max w-max p-1 m-1 items-center justify-center space-x-between">
                                        <h1>Zones</h1>
                                        <h1>{reports.regionCover.zone? reports.regionCover.zone : 0}</h1>
                                    </div>
                                    <div className="flex flex-col h-max w-max p-1 m-1 items-center justify-center space-x-between">
                                        <h1>States</h1>
                                        <h1>{reports.regionCover.state? reports.regionCover.state : 0}</h1>
                                    </div>
                                </div>    
                            </div>

                            <div className="flex flex-col h-max w-max p-2 m-1 items-center justify-center border border-slate-200 rounded shadow">
                                <h1 className="text-md font-bold my-1 text-blue-600">Assessment</h1>
                                <div className="flex flex-wrap">
                                    <div className="flex flex-col h-max w-max p-1 m-1 items-center justify-center space-x-between">
                                        <h1>Branch</h1>
                                        <h1>{reports.staffAssessed.branchCount? reports.staffAssessed.branchCount : 0}</h1>
                                    </div>
                                    <div className="flex flex-col h-max w-max p-1 m-1 items-center justify-center space-x-between">
                                        <h1>FLE</h1>
                                        <h1>{reports.staffAssessed.fleCount? reports.staffAssessed.fleCount : 0}</h1>
                                    </div>
                                    <div className="flex flex-col h-max w-max p-1 m-1 items-center justify-center space-x-between">
                                        <h1>Security</h1>
                                        <h1>{reports.staffAssessed.securityCount? reports.staffAssessed.securityCount : 0}</h1>
                                    </div>
                                </div>
                                
                            </div>

                            <div className="flex flex-col h-max w-max p-2 m-1 items-center justify-center border border-slate-200 rounded shadow">
                                <h1 className="text-md font-bold my-1 text-blue-600">Banks Assessed</h1>
                                <div className="flex flex-wrap">
                                    <div className="flex flex-col h-max w-max p-1 m-2 items-center justify-center space-x-between">
                                        <h1>GTB</h1>
                                        <h1>{reports.bankAssessed.gtb? reports.bankAssessed.gtb : 0}</h1>
                                    </div>
                                    <div className="flex flex-col h-max w-max p-1 m-2 items-center justify-center space-x-between">
                                        <h1>Stanbic</h1>
                                        <h1>{reports.bankAssessed.stabic? reports.bankAssessed.stabic : 0}</h1>
                                    </div>
                                    <div className="flex flex-col h-max w-max p-1 m-2 items-center justify-center space-x-between">
                                        <h1>Zenith</h1>
                                        <h1>{reports.bankAssessed.zenith? reports.bankAssessed.zenith : 0}</h1>
                                    </div>
                                    <div className="flex flex-col h-max w-max p-1 m-2 items-center justify-center space-x-between">
                                        <h1>Access</h1>
                                        <h1>{reports.bankAssessed.access? reports.bankAssessed.access : 0}</h1>
                                    </div>
                                </div>
                                
                            </div>

                            <div className="flex flex-col h-max w-max p-2 m-1 items-center justify-center border border-slate-200 rounded shadow">
                                <h1 className="text-md font-bold my-1 text-blue-600">Group Assessed</h1>
                                <div className="flex flex-wrap">
                                    <div className="flex flex-col h-max w-max p-1 m-2 items-center justify-center space-x-between">
                                        <h1>LPA</h1>
                                        <h1>{reports.group.groupOne? reports.group.groupOne : 0}</h1>
                                    </div>
                                    <div className="flex flex-col h-max w-max p-1 m-2 items-center justify-center space-x-between">
                                        <h1>Group 2</h1>
                                        <h1>{reports.group.groupTwo? reports.group.groupTwo : 0}</h1>
                                    </div>
                                    <div className="flex flex-col h-max w-max p-1 m-2 items-center justify-center space-x-between">
                                        <h1>Group 3</h1>
                                        <h1>{reports.group.groupThree? reports.group.groupThree : 0}</h1>
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                    )
                    :
                    <div className="w-full shadow rounded h-max p-4 m-3 border border-slate-100">loading data ...</div>
                }

                <div className="w-full m-1">
                    <input type='text' onChange={handleFilterSearch} value={ searchTerm } className="w-1/3 m-3 px-3 py-2 rounded-full border border-slate-300 focus:outline-blue-400" placeholder="Search Evaluator Code or Branch Code" />
                    <select onChange={handleFilterGroup} defaultValue={""} className="w-1/6 m-3 px-3 py-2 rounded border border-slate-300 focus:outline-blue-400 ">
                        <option value="">Filter Group</option>
                        <option value="1">LPA</option>
                        <option value="2">Group 2</option>
                        <option value="3">Group 3</option>
                    </select>
                    {/*<select onChange={handleFilter} defaultValue={"DEFAULT"} className="w-1/6 m-3 px-3 py-2 rounded border border-slate-300 focus:outline-blue-400 ">
                        <option value="DEFAULT" disabled>Filter Bank</option>
                        <option>Access</option>
                        <option>GTBank</option>
                        <option>Stanbic</option>
                        <option>Zenith</option>
                    </select>
                    <select onChange={handleFilterPerformance} className="w-1/6 m-3 px-3 py-2 rounded border border-slate-300 focus:outline-blue-400 ">
                        <option value="DEFAULT" disabled>Filter Score</option>
                        <option value="59">less than 60%</option>
                        <option value="60">60% and more</option>
                    </select>*/}
                </div>
                <div>
                    <div className="flex mb-0 justify-start h-max w-full">
                        <div id='all_assessment' onClick={handleSetTable} className={`w-1/2 text-center mx-1 p-4 md:w-1/4 lg:w-1/6 bg-slate-500 hover:bg-slate-700 text-white rounded-t-xl`}>
                            All
                        </div>
                        <div id='branch_assessment' onClick={handleSetTable} className={`w-1/2 text-center mx-1 p-4 md:w-1/4 lg:w-1/6 bg-orange-500 hover:bg-orange-700 text-white rounded-t-xl`}>
                            Branch
                        </div>
                        <div id='fle_assessment' onClick={handleSetTable} className={`w-1/2 text-center mx-1 p-4 md:w-1/4 lg:w-1/6 bg-green-500 hover:bg-green-700 text-white rounded-t-xl`}>
                            FLE
                        </div>
                        <div id='security_assessment' onClick={handleSetTable} className={`w-1/2 text-center mx-1 p-4 md:w-1/4 lg:w-1/6 bg-blue-500 hover:bg-blue-700 text-white rounded-t-xl`}>
                            Security
                        </div>
                        <ReactHtmlTableToExcel className={"p-2 m-2 text-white bg-green-500"} table={"currentReportEntries"} buttonText={"Download Table"} filename="Download_flagged_entries" sheet="flagged_entries_xls" />
                    </div>
                    <PaginatedTable data={currentEntriesFiltered} table={currentTable} pageLimit={10} dataLimit={10} id={'headerOfReport'}/>
                    <Table data={currentEntries} table={currentTable} hidden={true} id={'currentReportEntries'} />
                </div>
                    
            </section>
        </div>
    )
};

export default Reports;