//import { PersonRounded } from '@material-ui/icons';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { signOutThunk } from '../../features/authentication/authSlice';
import Logo from '../../utilities/Connectifie.png';

const AdminHeader = () => {
	const dispatch= useDispatch();	
	const { user } = useSelector(state=> state.auth);

	const handleLogout= (e) =>{
		dispatch(signOutThunk());
		sessionStorage.removeItem('token');
		sessionStorage.removeItem('evaluator_token');
		console.log(sessionStorage.getItem('token'));
		console.log(sessionStorage.getItem('evaluator_token'));
	}

	return (
		<div className="w-full h-14 bg-white sticky top-0 z-40 shadow-md">
			<div className="h-full px-5 py-0 flex items-center justify-between">
				<div className="flex items-center h-full w-1/2">
					<p className='text-slate-500'>Connectifie-MS</p>
					{/*<img src={Logo} alt="Logo" className="border  w-1/6 h-min" />*/}
				</div>
				<div className="flex items-center">
					<div className="flex mr-4">
						<p>Welcome<span className='text-blue-500 hover:text-blue-600 '>{user? `, ${user.firstname}` : ''}</span></p>
					</div>
					<button onClick={handleLogout} className="bg-red-600 hover:bg-red-900 px-2 py-1 rounded text-white capitalize text-sm">
						log out
					</button>
				</div>
			</div>
		</div>
	);
};

export default AdminHeader;
