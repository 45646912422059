const ProgressBar = ({ progressPercentage }) => {

    const progressColor= progressPercentage < 45 ? 'bg-red-600' : progressPercentage < 60? 'bg-orange-400' : 'bg-green-600';
    return (
        <div className="h-3 w-full bg-gray-300 rounded-full ">
            <div 
                className={`flex items-center justify-center rounded-full h-full text-white text-center ${progressColor}`} 
                style={{ width: `${progressPercentage}%`}}
            >
                <p className="font-bold">{progressPercentage}%</p>
            </div>
        </div>
    )
};

export default ProgressBar;