
import ReactApexChart from 'react-apexcharts';

const LineChart = ({ xaxis, data }) => {
    console.log(data , xaxis);
    const options = {
        series: [
            {
                name: "South West Trends",
                data: data.sw // for a week i.e 7 days
            },
            {
                name: "South South Trends",
                data: data.ss // for a week i.e 7 days
            },
            {
                name: "South East Trends",
                data: data.se // for a week i.e 7 days
            },
            {
                name: "LPA Trends",
                data: data.lpa // for a week i.e 7 days
            }
        ],
        colors: ['#0000ff', '#ff0000', '#00ff00', '#ffa500'],
        chart: {
            height: 250,
            type: 'line',
            zoom: {
                enabled: true,
            },
        },
        dataLabels: {
            enabled: false
        },
        stroke: {
            curve: 'straight',
            width: 2,
            lineCap: 'butt'
        },
        title: {
            text: 'Scope performace trend by zones',
            align: 'left'
        },
        /*grid: {
            row: {
                colors: ['#41B883', '#afafaf', '#57a850'],
                opacity: 0.5
            }
        },*/
        yaxis: {
            show: true,
            label: {
                show: true,
                formatter: (value) => { return value + '%'}
            },
            title: {
                text: 'Percentage Performance (%)',
            }
        },
        xaxis: {
            categories: xaxis,
        }
    };

    return (
        <div>
            <ReactApexChart options={options} series={options.series} height={300} />
        </div>
    )

};

export default LineChart;