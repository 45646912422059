
const SidebarItem = ({ onClick, icon, label, active, activeIcon }) => {
	const classes= ' bg-inherit w-full h-max px-4 py-2 lg:p-4 text-white text-left hover:scale-110 hover:px-6  ' // The Class Style 

	return (
		<li onClick={onClick} className={classes} data-active={active}>
			{icon} <span>{label}</span>
			{activeIcon}
		</li>
	);
};

export default SidebarItem;
