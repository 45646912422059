import { useEffect, useState } from "react"
import ReactHtmlTableToExcel from "react-html-table-to-excel"
import { useDispatch, useSelector } from "react-redux"
import PaginatedTable from "../../components/tables/PaginatedTable"
import Table from "../../components/tables/Table"
import { flaggedBranchesThunk } from "../adminSummary/summarySlice"
import { today } from "../report/Report"
import { flaggedThunk, unflagReportThunk, acceptFlagThunk } from "./flaggedIssuesSlice";


const table = {
    flaggedEntries: {
        name: 'flagged',
        tHeaders: [ 'Evaluator Code', 'Business Reason', 'Date Created', 'Actions'],
        tProperties: [ 'Evaluator_id', 'reason', 'date_created']
    },
    flaggedBranches: {
        name: 'flagged',
        tHeaders: [ 'Evaluator Code', 'Business Reason', 'Date Created', 'Actions'],
        tProperties: [ 'Evaluator_id', 'reason', 'date_created']
    },
}




const FlaggedIssues = () => {
    const { branches, entries } = useSelector(state => state.flagged);
    const reports = useSelector(state => state.reports);
    const dispatch = useDispatch();

    const [checkedData, setCheckedData] = useState([]);
    useEffect(()=>{
        const checkableData = entries ? entries.map(item => {
            return {
                ...item,
                checked: false
            }
        }) : [];
        setCheckedData(checkableData);
    }, [entries])

    const [ selectDate, setSelectdate ] = useState(today);
    const handleDateSelect = (e) => {
        setSelectdate(e.target.value);
    };
    useEffect(() => {
        dispatch(flaggedThunk(selectDate));
    }, [dispatch, selectDate]);


    const handleUnflagReport = (report) => {
        dispatch(unflagReportThunk(report.flag_id))
        //const flagBearer= evaluators.find(evaluator => evaluator.access_code === report.Evaluator_id);
        //console.log({ flagBearer });
        //toast.remove();
        //toast(`Evaluator is ${flagBearer.full_name} in ${flagBearer.state} state under the ${flagBearer.zone} zone.`, { duration: 10000 });
    }

    const handleAcceptFlag = (report) => {
        dispatch(acceptFlagThunk(report.flag_id))
        //const flagBearer= evaluators.find(evaluator => evaluator.access_code === report.Evaluator_id);
        //console.log({ flagBearer });
        //toast.remove();
        //toast(`Evaluator is ${flagBearer.full_name} in ${flagBearer.state} state under the ${flagBearer.zone} zone.`, { duration: 10000 });
    }

    const [ selectedEntries, setSelectedEntries ] = useState([]);

    const onClickSelect = (record, i) => {
        console.log(record);
        checkedData[i].checked = !checkedData[i].checked;
        if (selectedEntries.includes(record)) {
            setSelectedEntries(selectedEntries.filter(entry => entry !== record));
        } else {
            setSelectedEntries([ ...selectedEntries, record ]);
        }
        console.log(selectedEntries);
    }


    return (
        <div className='flex flex-col'>
            <h1 className="font-bold text-xl text-blue-800 mx-auto my-4">Manage Flagged Issues</h1>
            <div className="flex items-end">
                <h1 className="self-start font-bold text-lg text-blue-700">Flagged Issues: </h1>
                <select onChange={handleDateSelect} defaultValue={today} className="font-medium text-blue-500 mx-3 pb-0.5 text-sm focus:outline-none hover:border hover:border-slate-100 hover:shadow-md focus:border focus:border-slate-100 focus:shadow-md">
                    <option value={today}> Today </option>
                    {
                        reports.dates? reports.dates.map(date => {
                            return <option key={date} value={date}>{date}</option>
                        }) : null
                    }
                </select>

            </div>
            <section className="w-full p-5 m-4 bg-white shadow-md rounded "> 
                <h1 className="self-start font-bold text-lg text-red-700">Flagged Entries {/*: <span className="font-medium text-blue-500 text-sm">{reportDate}</span>*/}</h1>
                <div className="w-full m-1">
                    <ReactHtmlTableToExcel className='rounded p-3 bg-blue-600 hover:bg-blue-600 text-white' table={"flaggedEntries"} buttonText={"Download Flagged Entries as XLS"} filename="Download_flagged_entries" sheet="flagged_entries_xls" />
                    <button className='rounded p-3 mx-2 bg-blue-600 hover:bg-blue-500 text-white'>Unflag {selectedEntries.length? selectedEntries.length : null} Entries</button>
                    <button className='rounded p-3 mx-2 bg-red-400 hover:bg-red-600 text-white'>Approve {selectedEntries.length? selectedEntries.length : null} Flagged Entries</button>
                </div>
                <PaginatedTable data={checkedData} table={table.flaggedEntries} pageLimit={10} dataLimit={5}  isSelectable={true} onClickSelect={onClickSelect} isAction={true} actionElements={[{text: "Approve", onClick: handleAcceptFlag, bgColor: 'bg-red-500'}, {text: "Delete", onClick: handleUnflagReport, bgColor: 'bg-blue-500' }]} />
                <Table data={checkedData} table={table.flaggedEntries} hidden={true} id={"flaggedEntries"} />
            </section>

            <section className="w-full p-5 m-4 bg-white shadow-md rounded ">
                <h1 className="self-start font-bold text-lg text-red-700">Flagged Branches</h1>
                <div className="w-full m-1">
                    <ReactHtmlTableToExcel buttonText="Download Flagged Branches as XLS" className='rounded p-3 bg-blue-600 hover:bg-blue-600 text-white' table="flagged-branches-table" filename="Download_flagged_branches" sheet="flagged_branches_xls" />
                </div>
                <PaginatedTable data={branches} table={table.flaggedBranches} pageLimit={10} dataLimit={5}  isAction={true} actionElements={[/*{text: "View Evaluator", onClick: testOnClick }, */{text: "Unflag Report", onClick: handleUnflagReport, bgColor: 'bg-green-500' }]} />
                <Table hidden={true} data={branches} table={table.flaggedBranches} id={"flagged-branches-table"} />
            
            </section>
        </div>
    )
}

export default FlaggedIssues;