import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { createCampaign, getAllCampaigns, getAllTasks, getDayTasks, bulkAssignTasks } from "./corporateAPI";

const initialState = {};
const reducers = {};

export const createCampaignThunk = createAsyncThunk('corporate/createCampaign', async (formData, {getState})=>{
    const state = getState();
    const { token } = state.auth;
    if (token) {
    const payload = await createCampaign(formData, token);
    return { campaignCreateSuccess: payload.success };
    }
});

export const getAllCampaignsThunk = createAsyncThunk('corporate/loadCampaigns', async (_, {getState})=>{
    const state = getState();
    const { token } = state.auth;
    if(token) {
        const payload = await getAllCampaigns(token);
        return { allCampaigns: payload };
    }
});

export const getAllTasksThunk = createAsyncThunk('corporate/loadTasks', async (_, {getState})=>{
    const state = getState();
    const { token } = state.auth;
    if(token) {
        const payload = await getAllTasks(token);
        return { allTasks: payload };
    }
});

export const getDayTasksThunk = createAsyncThunk('corporate/loadTasks', async (date, {getState})=>{
    const state = getState();
    const { token } = state.auth;
    if(token) {
        const payload = await getDayTasks(token, date);
        return { dayTasks: payload };
    }
});

export const bulkUploadTaskThunk = createAsyncThunk('corporate/bulkUploadTask', async (formData, {getState})=>{
    const state = getState();
    const { token } = state.auth;
    if (token) {
        const payload = await bulkAssignTasks(formData, token);
        return { bulkUploadSuccess: payload.success };
    }
});

const corporateSlice = createSlice({
    name: 'corporate',
    initialState,
    reducers,
    extraReducers: (builder)=>{
        builder
            .addCase(createCampaignThunk.fulfilled, (state, action)=> ({...state, ...action.payload }))
            .addCase(getAllCampaignsThunk.fulfilled, (state, action)=> ({...state, ...action.payload }))
            .addCase(getAllTasksThunk.fulfilled, (state, action)=> ({...state, ...action.payload }))
    }
});

export default corporateSlice.reducer;