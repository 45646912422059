import { useForm } from "react-hook-form";
import { greetTime } from "../Signin";

const AdminSignin = ({onSubmitForm}) => {
	const { register, handleSubmit } = useForm();

    return (
        <form className="w-full md:w-3/4 lg:w-1/2" onSubmit={handleSubmit(onSubmitForm)}>
					<div className="flex md:items-center justify-start w-full  overflow-hidden">
						<h1
							className="text-2xl md:w-3/4 object-contain font-medium text-slate-800"
						>
							Welcome Back, Admin
						</h1>
					</div>
					<p className="my-4 text-slate-500">
						Good {greetTime()}, Please sign in
					</p>
					<input
						id="login_email"
						className="block w-full p-2 bg-white rounded-md mb-4 border border-slate-700 focus:outline-0 focus:border-accessOrange hover:border-accessOrange lowercase"
						type="email"
						{...register('email', {
							required: 'required',
						})}
						placeholder="Enter email address"
					/>
					<input
						id="login_password"
						className="block w-full p-2 bg-white rounded-md mb-4 border border-slate-700 focus:outline-0 focus:border-accessOrange hover:border-accessOrange"
						type="password"
						{...register('password', {
							required: 'required',
						})}
						placeholder="Enter Admin Code"
					/>
					<span className="text-slate-700">
						<input type="checkbox" name="keep_signed" /> Keep me signed in
					</span>
					<br />
					<input
						id="submit"
						type="submit"
						value="Sign in"
						className="bg-blue-900 text-white w-full mt-4 mx-auto rounded-md cursor-pointer font-bold py-2 hover:bg-blue-700"
					/>
				</form>
    )
}

export default AdminSignin;