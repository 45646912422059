import axios from "axios";
import { today } from "../report/Report";

const baseURL = 'http://18.134.133.59:4000/api/v1.0';

export const fetchDataSummary = async (token) =>{
    const { data } = await axios.get(`${baseURL}/evaluator-list?campaign_id=2`, {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    });
    const dataSummary= {
        statusCount: data.StatusCount,
        zoneCount: data.zoneCount,
        stateCount: data.stateCount
    }
    console.log({ dataSummary });

    return dataSummary;
}

export const fetchZonalTrend = async (token, dates) =>{
    const response = await dates.map(async (date)=> {
        const {data} = await axios.get(`${baseURL}/campaign/assessment/scope/day/${date}?campaign_id=2`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        const { zoneScope, groupScope } = data;
        return {...zoneScope, ...groupScope};
    });

    const zonalTrend = await Promise.all(response);

    console.log({ zonalTrend });

    const makePercentage= (done, assigned) => {
        if(assigned === 0 || done === 0) {
            return 0;
        }
        return Math.round((done/assigned)*100) > 100 ? 100 : Math.round((done/assigned)*100);
    }


    const pastWeekZoneResult = zonalTrend.map(zoneScope=>{
        return {
            groupOne: makePercentage(zoneScope.groupOne.done, zoneScope.groupOne.assigned),
            northCentral: makePercentage(zoneScope.nc.done, zoneScope.nc.assigned),
            northEast: makePercentage(zoneScope.ne.done, zoneScope.ne.assigned),
            northWest: makePercentage(zoneScope.nw.done, zoneScope.nw.assigned),
            southEast: makePercentage(zoneScope.se.done, zoneScope.se.assigned),
            southSouth: makePercentage(zoneScope.ss.done, zoneScope.ss.assigned),
            southWest: makePercentage(zoneScope.sw.done, zoneScope.sw.assigned),
        }
    })
    console.log({ pastWeekZoneResult });
    return pastWeekZoneResult;

}

export const fetchOverallScopeCoverage = async (token, campaignID) =>{
    const { data: scopeCoverage } = await axios.get(`${baseURL}/campaign/assessment/scope?campaign_id=2`, {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    });
    return scopeCoverage;
}

export const fetchDailyScopeCoverage = async (token, date) => {
    const { data: dailyScope } = await axios.get(`${baseURL}/campaign/assessment/scope/day/${date}?campaign_id=2`, {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    });
    return dailyScope;
}

