import { useSelector } from "react-redux";
import DonutChart from "../../components/charts/DonutChart";
import NairaIcon from "../../utilities/nairaIcon.png";
import ColumnChart from "../../components/charts/ColumnChart";


const EvaluatorSummary = () => {
    const { evaluator } = useSelector(state=> state.auth);

    const data = {
        assigned: 28,
        done: 4
    }

    return (
        <div className="p-2 flex flex-col bg-slate-100 h-full">
            <div className="my-3">
                <p className="font-bold text-slate-700">Welcome <span className='text-slate-600 hover:text-blue-600 capitalize'>{evaluator && evaluator.user? `, ${evaluator.user.evaluator_name.split(' ')[0]}` : 'Stranger'}</span></p>
                <p className="text-slate-800 text-sm">Total Earning: <span className="text-green-600">NGN {`12,000.00`}</span></p>
            </div>
            

            <section className="flex flex-col items-center justify-center mb-3 shadow rounded ">
                <div className="flex w-full items-center justify-center" >
                    <div className="bg-white shadow rounded border w-1/3 m-1 p-2">
                        <h2 className="font-bold text-center text-blue-800 text-md">Branch Assigned</h2>
                        <div className="flex items-center justify-center mt-4">
                            
                            <p className="font-bold text-lg text-slate-700">{`6`}</p>
                        </div>
                    </div>
                    <div className="bg-white shadow rounded border w-1/3 m-1 p-2">
                        <h2 className="font-bold text-center text-blue-800 text-md">FLE Assigned</h2>
                        <div className="flex items-center justify-center mt-4">

                            <p className="font-bold text-lg text-slate-700">{`24`}</p>
                        </div>
                    </div>
                    <div className="bg-white shadow rounded border w-1/3 m-1 p-2">
                        <h2 className="font-bold text-center text-blue-800 text-md">Security Assigned</h2>
                        <div className="flex items-center justify-center mt-4">

                            <p className="font-bold text-lg text-slate-700">{`12`}</p>
                        </div>
                    </div>
                </div>
                <div className="flex w-full items-center justify-center" >
                    <div className="bg-white shadow rounded border w-1/3 m-1 p-2">
                        <h2 className="font-bold text-center text-slate-800 text-md">Branch Assessed</h2>
                        <div className="flex items-center justify-center mt-4">
                            
                            <p className="font-bold text-lg text-green-700">{`4`}</p>
                        </div>
                    </div>
                    <div className="bg-white shadow rounded border w-1/3 m-1 p-2">
                        <h2 className="font-bold text-center text-slate-800 text-md">FLE Assessed</h2>
                        <div className="flex items-center justify-center mt-4">

                            <p className="font-bold text-lg text-green-700">{`15`}</p>
                        </div>
                    </div>
                    <div className="bg-white shadow rounded border w-1/3 m-1 p-2">
                        <h2 className="font-bold text-center text-slate-800 text-md">Security Assessed</h2>
                        <div className="flex items-center justify-center mt-4">

                            <p className="font-bold text-lg text-green-700">{`8`}</p>
                        </div>
                    </div>
                </div>
                
            </section>

            <section className="my-2 py-4 shadow">
                <h2 className="self-start font-bold text-slate-700 text-md">Daily Tasks:</h2>
                <div className="flex my-2 w-full">
                    <div className="rounded-md border p-3 m-1 bg-white shadow-md w-1/2">
                        <p className="font-bold text-slate-800">Branch: <br/><span className="text-slate-500 font-bold">ACCESS 625</span></p>
                        <p className="font-bold text-slate-800 ">Assessment Type: <br/><span className="text-slate-500 font-bold">All</span></p>
                        <button className="border border-blue-600 font-bold p-1 mt-3 text-sm text-blue-600 w-full rounded">View</button>
                    </div>
                    <div className="rounded-md border p-3 m-1 bg-white shadow-md w-1/2">
                        <p className="font-bold text-slate-800">Branch: <br/><span className="text-slate-500 font-bold">GTB 625</span></p>
                        <p className="font-bold text-slate-800 ">Assessment Type: <br/><span className="text-slate-500 font-bold">All</span></p>
                        <button className="border border-blue-600 font-bold p-1 mt-3 text-sm text-blue-600 w-full rounded">View</button>
                    </div>
                </div>
                
                <div className="bg-white rounded border py-3 md:w-full md:flex md:justify-center">
                    <DonutChart data={[data.done, (data.assigned - data.done)]} titleText={`Daily Task Completion: ${data.done} / ${data.assigned}`} />
                </div>
            </section>

            <section className=" p-2 my-4 shadow">
                <h2 className="font-bold text-slate-600 mb-2">Campaign Summary</h2>
                <div className="border p-1">
                    <p className="bg-white shadow border rounded p-2 my-1 font-bold text-slate-700">Total Earning: <span className="text-green-500 "> NGN {12000}</span></p>
                    <p className="bg-white shadow border rounded p-2 my-1 font-bold text-slate-600">Tasks Assigned:  <span className="text-slate-600">38</span></p>
                    <p className="bg-white shadow border rounded p-2 my-1 font-bold text-slate-600">Reports Pending:  <span className="text-slate-600">0</span></p>
                    <p className="bg-white shadow border rounded p-2 my-1 font-bold text-green-900">Reports Approved:  <span className="text-green-800">23</span></p>
                    <p className="bg-white shadow border rounded p-2 my-1 font-bold text-red-600">Reports Rejected:  <span className="text-red-600">1</span></p>
                </div>
            </section>
            <section className="overflow-x-scroll">
                <h2 className="font-bold text-slate-600 mb-2">Tasks Summary History</h2>
                <ColumnChart
                    data={[[13, 1], [14, 0],  [14, 0]]}
                    xaxis={[`12-04-2022`, `13-04-2022`, `14-04-2022`]}
                    titleText={``}
                    horizontal={false}
                />
            </section>

            <section className="my-3">
                <div className="flex justify-around items-center p-1 border bg-blue-100">
                    <h2 className="font-bold text-slate-600 mb-2">Reports History</h2>
                    <select className="p-2 mx-1 rounded">
                        {
                            [`12-04-2022`, `13-04-2022`, `14-04-2022`].map((date, i)=> {
                                return <option key={i}>{date}</option>
                            })
                        }
                    </select>
                    <select className="p-2 mx-1 rounded">
                        <option>Branch</option>
                        <option>FLE</option>
                        <option>Security</option>
                    </select>
                </div>
                <div className="overflow-x-scroll">
                    <table className="table-auto w-full">
                        <thead className='text-center w-max text-slate-700 shadow m-1 border'>
                            {
                                ['Date', 'Branch', 'Status'].map((th, index) => <th key={index}>{th}</th>)
                            }
                        </thead>
                        <tbody>
                            {
                                [1, 2, 3].map((tr, index) => {
                                    return (
                                        <tr key={index} className='text-center w-max text-slate-700 shadow m-1 border'>
                                            <td className="text-sm p-2 ">12-04-2022</td>
                                            <td className="text-sm p-2 text-accessOrange ">ACCESS 650</td>
                                            <td className="text-sm p-2 text-blue-700 ">Approved</td>
                                        </tr>
                                    )
                                })
                                
                            }
                            <tr className='text-center w-max text-slate-700 shadow m-1 border'>
                                <td className="text-sm p-2 ">12-04-2022</td>
                                <td className="text-sm p-2 text-accessOrange ">GTB 650</td>
                                <td className="text-sm p-2 text-red-700 ">Rejected</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </section>
        </div>
    )
}

export default EvaluatorSummary;