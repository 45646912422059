import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { fetchBranchList } from "./branchAPI";

const initialState = [];
const reducers = {};

export const branchListThunk = createAsyncThunk('branches/load', async (_, { getState })=>{
    const { token } = getState().auth;
    const payload = await fetchBranchList(token);
    return payload;
});

const branchListSlice = createSlice({
    name: 'branches',
    initialState,
    reducers,
    extraReducers: (builder)=>{
        builder
            .addCase(branchListThunk.fulfilled, (state, action)=> [ ...action.payload ])
    }
});

export default branchListSlice.reducer;