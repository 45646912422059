import { useState, useEffect } from "react";

const PaginatedTable = ({ data, table, pageLimit, dataLimit, id= 'theadReports', isAction, isSelectable, onClickSelect, actionElements=[] }) => {
    
    //console.log(data)
    const [pages, setPages] = useState( data && data.length? Math.ceil(data.length / dataLimit) : 0);
    const [currentPage, setCurrentPage] = useState(1);
    useEffect(()=>{
        const pages = data && data.length? Math.ceil(data.length / dataLimit) : 0;
		setPages(pages);
    },[data, dataLimit]);
    
    // Methods
    const gotoNextPage = (e) =>{
        e.preventDefault();
        currentPage === pages? setCurrentPage(pages) :
        setCurrentPage((prev)=> {return prev + 1});
    };

    const gotoPreviousPage = (e) =>{
        e.preventDefault();
        currentPage === 1? setCurrentPage(1) :
        setCurrentPage((prev)=> prev - 1);
    };

    const changePage = (event) =>{
        event.preventDefault();
        Number(event.target.innerText) > pages ? setCurrentPage(pages) : setCurrentPage(Number(event.target.innerText));
    };
    
    const getDataByPage = () =>{
        const startIndex= (currentPage * dataLimit) - dataLimit;
        const endIndex= currentPage * dataLimit;
        const pageData = data && data.length? data.slice(startIndex, endIndex) : [];
        return pageData;
    };

    const getPaginationGroup = () => {
        let start = Math.floor((currentPage - 1) / pageLimit) * pageLimit;
        const pagesGroup= new Array(pageLimit).fill().map((_, index) => start + index + 1);
        return pagesGroup;
    };





    return (
        <div>
            <div className='overflow-x-scroll'>
                <table
                    id="Data"
                    className="border overflow-scroll border-slate-200 w-full divide-y divide-gray-200 table-auto z-10 mx-auto"
                >
                    <thead id={id} className="bg-gray-200">
                        <tr>
                            {isSelectable? (<th className="w-50">

                            </th>): null}
                            <th
                                scope="col"
                                className="py-3 px-6 text-xs font-medium tracking-wider text-center text-gray-900 uppercase"
                            >
                                S/N
                            </th>
                            {table.tHeaders.map((tableheader, i) => {
                                return (
                                    <th
                                        scope="col"
                                        key={i}
                                        className="py-3 px-6 text-xs font-medium tracking-wider text-center text-gray-900 uppercase"
                                    >
                                        {tableheader}
                                    </th>
                                );
                            })}
                        </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                        {getDataByPage().map((record, i) => {
                            const index = (currentPage * dataLimit) - dataLimit + i;
                            console.log(index)
                            return (
                                <tr className="hover:bg-gray-100" key={index}>
                                    {isSelectable? (
                                    <td className="px-4">
                                        <input type='checkbox' checked={record.checked} onClick={()=>{ onClickSelect(record, index) }}></input>
                                    </td>) : null}
                                    <td className="py-4 px-6 text-sm font-medium text-gray-900 text-center whitespace-normal">
                                        {(dataLimit * currentPage)-(dataLimit-1) + i}
                                    </td>
                                    {table.tProperties.map((property, i) => {
                                        return (	
                                            <td
                                                key={i}
                                                className={`status py-4 px-6 text-sm font-medium text-center text-gray-900 whitespace-normal`}
                                            >
                                                { record[`${property}`] }
                                            </td>
                                        );
                                    })}
                                    {
                                        isAction && actionElements.length? (
                                        <td className="flex justify-center w-full h-full p-2">
                                            {
                                                actionElements.map((actionElement, i)=>{
                                                    return <button key={i} onClick={()=>{ actionElement.onClick(record) }} className={`${actionElement.bgColor? actionElement.bgColor : 'bg-blue-500'} text-white mx-1 p-2 focus:bg-blue-600 hover:bg-blue-600`}>{actionElement.text}</button>
                                                })
                                            }
                                        </td>
                                        ) : null
                                    }
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>

            <div className="flex justify-center items-center w-full">
                {/* previous button */}
                <button
                    onClick={gotoPreviousPage}
                    className={`prev ${currentPage === 1 ? 'cursor-not-allowed' : ''}`}
                >
                    prev
                </button>

                {/* show page numbers */}
                {getPaginationGroup().map((item, index) => (
                    <button
                    key={index}
                    value={item}
                    onClick={changePage}
                    className={`w-max m-1 p-3 rounded-lg text-white hover:bg-slate-500 ${currentPage === pages ? 'cursor-not-allowed' : ''} ${currentPage === item ? 'bg-slate-600' : 'bg-slate-200'}`}
                    >
                    <span>{item}</span>
                    </button>
                ))}

                {/* next button */}
                <button
                    onClick={gotoNextPage}
                    className={`next ${currentPage === pages ? 'cursor-not-allowed' : ''}`}
                >
                    next
                </button>
            </div>
        </div>
        
    );
};

export default PaginatedTable;