import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { fetchDataSummary, fetchZonalTrend, fetchOverallScopeCoverage, fetchDailyScopeCoverage } from "./summaryAPI";

const initialState = {};
const reducers = {};

export const dataSummaryThunk = createAsyncThunk('dataSummary/load', async (_, { getState })=>{
    const { token } = getState().auth;
    const payload = await fetchDataSummary(token);
    return {...payload};
});

export const pastWeekZoneScopeTrendThunk = createAsyncThunk('dataSummary/zoneTrend', async (dates, { getState })=>{
    const { token } = getState().auth;
    const payload= await fetchZonalTrend(token, dates);
    return { zonalTrend: payload };
})

export const overallScopeCoverageThunk = createAsyncThunk('dataSummary/loadOverallScopeCoverage', async (_, { getState })=>{
    const { token } = getState().auth;
    const payload= await fetchOverallScopeCoverage(token);
    return { overallScopeCoverage: payload };
})

export const dailyScopeCoverageThunk = createAsyncThunk('dataSummary/loadDailyScopeCoverage', async (date, { getState })=>{
    const { token } = getState().auth;
    const payload= await fetchDailyScopeCoverage(token, date);
    return { dailyScopeCoverage: payload };
})



const dataSummarySlice = createSlice({
    name: 'dataSummary',
    initialState,
    reducers,
    extraReducers: (builder)=>{
        builder
            .addCase(dataSummaryThunk.fulfilled, (state, action)=> ({...state, ...action.payload }))
            .addCase(pastWeekZoneScopeTrendThunk.fulfilled, (state, action)=> ({...state, ...action.payload }))
            .addCase(overallScopeCoverageThunk.fulfilled, (state, action)=> ({...state, ...action.payload }))
            .addCase(dailyScopeCoverageThunk.fulfilled, (state, action)=> ({...state, ...action.payload }))
    }
});

export default dataSummarySlice.reducer;
