import React from 'react';
import AdminDashboard from '../pages/AdminDashboard';
import EvaluatorDashboard from '../pages/EvaluatorDashboard';
import { Routes, Route } from 'react-router-dom';
import SigninPage from '../features/authentication/Signin';
import { Toaster } from 'react-hot-toast';

function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<SigninPage />} />
        <Route path="/admin" element={<AdminDashboard />} />
        <Route exact path="/evaluator"  element={<EvaluatorDashboard />} />
      </Routes>

      <Toaster />
    </>
  );
}

export default App;
