import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { fetchReport, unflagReport, acceptFlagReport } from "./reportAPI";

const initialState = {};
const reducers = {};

export const reportsThunk = createAsyncThunk('reports/load', async (date, { getState })=>{
    const { token } = getState().auth;
    const payload = await fetchReport(date, token);
    return payload;
});

export const unflagReportThunk = createAsyncThunk('reports/unflag', async (flagID, { getState })=>{
    const { token } = getState().auth;
    const payload = await unflagReport(token, flagID);
    return payload;
});

export const acceptFlagThunk = createAsyncThunk('reports/acceptFlag', async (flagID, { getState })=>{
    const { token } = getState().auth;
    const payload = await acceptFlagReport(token, flagID);
    return payload;
});

const reportSlice = createSlice({
    name: 'reports',
    initialState,
    reducers,
    extraReducers: (builder)=>{
        builder
            .addCase(reportsThunk.fulfilled, (state, action)=> ({ ...action.payload }))
    }
});

export default reportSlice.reducer;