import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { assignTask, fetchEvaluatorList } from "./evaluatorAPI";

const initialState = [];
const reducers = {};

export const evaluatorListThunk = createAsyncThunk('evaluators/load', async (_, { getState })=>{
    const { token } = getState().auth;
    const payload = await fetchEvaluatorList(token);
    return payload;
});

export const assignTaskThunk = createAsyncThunk('evaluators/assign-task', async (data, { getState })=>{
    const { token } = getState().auth;
    const preparedData = data.map(item => ({
        campaign_id: item.campaign_id,
        evaluator_access_code: item.evaluator_access_code,
        branch_id: item.branch_id,
        date_assigned: item.date_assigned,
        task_type: item.task_type,
    }));
    console.log({ preparedData })
    const payload = await assignTask(preparedData, token);
    console.log({ payload })
    return payload;
});

const evaluatorListSlice = createSlice({
    name: 'evaluators',
    initialState,
    reducers,
    extraReducers: (builder)=>{
        builder
            .addCase(evaluatorListThunk.fulfilled, (state, action)=> [ ...action.payload ])
    }
});

export default evaluatorListSlice.reducer;