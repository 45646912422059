import classes from './AdminSidebar.module.css';
import SidebarItem from '../SidebarItem/SidebarItem';
/*import {
	ChevronRightRounded,
	KeyboardArrowDownRounded
} from '@material-ui/icons';*/

const AdminSidebar = ({ pages, setPage, currentPage }) => {
	return (
		<div className={`${classes.Sidebar} flex-1 sticky bg-slate-100`} style={{ backgroundColor: '#405189' }}>
			<div>
				<div className="mb-3 p-5">
					{/* <h3>Dashboard</h3> */}
					<ul>
						{pages.map((page) => {
							// const activeIcon= page.id === currentPage? <KeyboardArrowDownRounded /> : <ChevronRightRounded />;
							return (
								<SidebarItem
									key={page.title}
									label={page.title}
									icon={page.icon}
									onClick={() => setPage(page.id)}
									active={currentPage === page.id}

								/>



							);
						})}
					</ul>
				</div>
			</div>
		</div>
	);
};

export default AdminSidebar;
